import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ShareRoomNotice = function ShareRoomNotice() {
  this.lUid = 0;
  this.lRoomId = 0;
  this.sNickName = "";
  this.sChannelName = "";
  this.sChannelIcon = "";
  this.iShareType = 0;
  this.lComboNum = 0;
  this.sComboId = "";
  this.sAnchorName = "";
};
ShareRoomNotice.prototype._clone = function () {
  return new Nimo.ShareRoomNotice();
};
ShareRoomNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRoomNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRoomNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt64(1, this.lRoomId);
  os.writeString(2, this.sNickName);
  os.writeString(3, this.sChannelName);
  os.writeString(4, this.sChannelIcon);
  os.writeInt32(5, this.iShareType);
  os.writeInt64(6, this.lComboNum);
  os.writeString(7, this.sComboId);
  os.writeString(8, this.sAnchorName);
};
ShareRoomNotice.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.sNickName = is.readString(2, false, this.sNickName);
  this.sChannelName = is.readString(3, false, this.sChannelName);
  this.sChannelIcon = is.readString(4, false, this.sChannelIcon);
  this.iShareType = is.readInt32(5, false, this.iShareType);
  this.lComboNum = is.readInt64(6, false, this.lComboNum);
  this.sComboId = is.readString(7, false, this.sComboId);
  this.sAnchorName = is.readString(8, false, this.sAnchorName);
};
Nimo.ShareRoomNotice = ShareRoomNotice;
export default ShareRoomNotice;
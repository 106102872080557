import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserInfo';
import './MsgUserAttribute';
var MsgCommType = function MsgCommType() {
  this.sTitle = "";
  this.sHead = "";
  this.sBody = "";
  this.mAttribute = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sPic = "";
  this.iActionType = 0;
  this.sAction = "";
  this.iMsgType = 0;
  this.iFlag = 0;
  this.iWebActType = 0;
  this.sWebAction = "";
  this.iSensitive = 0;
  this.sSensitiveMsg = "";
  this.iMsgCommFormatType = 0;
  this.tSrcUser = new Nimo.UserInfo();
  this.mSubscript = new Taf.Map(new Taf.STRING(), new Taf.Vector(new Taf.STRING()));
  this.tSrcUserAttribute = new Nimo.MsgUserAttribute();
  this.mBizData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sActionContent = "";
};
MsgCommType.prototype._clone = function () {
  return new Nimo.MsgCommType();
};
MsgCommType.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgCommType.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgCommType.prototype.writeTo = function (os) {
  os.writeString(0, this.sTitle);
  os.writeString(1, this.sHead);
  os.writeString(2, this.sBody);
  os.writeMap(3, this.mAttribute);
  os.writeString(4, this.sPic);
  os.writeInt32(5, this.iActionType);
  os.writeString(6, this.sAction);
  os.writeInt32(7, this.iMsgType);
  os.writeInt32(8, this.iFlag);
  os.writeInt32(9, this.iWebActType);
  os.writeString(10, this.sWebAction);
  os.writeInt32(11, this.iSensitive);
  os.writeString(12, this.sSensitiveMsg);
  os.writeInt32(13, this.iMsgCommFormatType);
  os.writeStruct(14, this.tSrcUser);
  os.writeMap(15, this.mSubscript);
  os.writeStruct(16, this.tSrcUserAttribute);
  os.writeMap(17, this.mBizData);
  os.writeString(18, this.sActionContent);
};
MsgCommType.prototype.readFrom = function (is) {
  this.sTitle = is.readString(0, false, this.sTitle);
  this.sHead = is.readString(1, false, this.sHead);
  this.sBody = is.readString(2, false, this.sBody);
  this.mAttribute = is.readMap(3, false, this.mAttribute);
  this.sPic = is.readString(4, false, this.sPic);
  this.iActionType = is.readInt32(5, false, this.iActionType);
  this.sAction = is.readString(6, false, this.sAction);
  this.iMsgType = is.readInt32(7, false, this.iMsgType);
  this.iFlag = is.readInt32(8, false, this.iFlag);
  this.iWebActType = is.readInt32(9, false, this.iWebActType);
  this.sWebAction = is.readString(10, false, this.sWebAction);
  this.iSensitive = is.readInt32(11, false, this.iSensitive);
  this.sSensitiveMsg = is.readString(12, false, this.sSensitiveMsg);
  this.iMsgCommFormatType = is.readInt32(13, false, this.iMsgCommFormatType);
  this.tSrcUser = is.readStruct(14, false, this.tSrcUser);
  this.mSubscript = is.readMap(15, false, this.mSubscript);
  this.tSrcUserAttribute = is.readStruct(16, false, this.tSrcUserAttribute);
  this.mBizData = is.readMap(17, false, this.mBizData);
  this.sActionContent = is.readString(18, false, this.sActionContent);
};
Nimo.MsgCommType = MsgCommType;
export default MsgCommType;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftEffectID';
import './RichText';
var SendGiftEffectBroadcastPacket = function SendGiftEffectBroadcastPacket() {
  this.iItemType = 0;
  this.iItemCount = 0;
  this.lPresenterUid = 0;
  this.lSenderUid = 0;
  this.sPresenterNick = "";
  this.sSenderNick = "";
  this.lRoomId = 0;
  this.iComboScore = 0;
  this.iPayType = 0;
  this.mUpgradeEffect = new Taf.Map(new Taf.INT32(), new Taf.INT64());
  this.iPlay = 0;
  this.sAlise = "";
  this.iJumpType = 0;
  this.sJumpUrl = "";
  this.sSenderAvatarUrl = "";
  this.sRecipientNick = "";
  this.lRecipientId = 0;
  this.mBigAnimEffectText = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mBigAnimEffectImage = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iGiftEffectSource = 0;
  this.tEffectID = new Nimo.GiftEffectID();
  this.vAvatarUrls = new Taf.Vector(new Taf.STRING());
  this.tRichText = new Nimo.RichText();
};
SendGiftEffectBroadcastPacket.prototype._clone = function () {
  return new Nimo.SendGiftEffectBroadcastPacket();
};
SendGiftEffectBroadcastPacket.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendGiftEffectBroadcastPacket.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendGiftEffectBroadcastPacket.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iItemType);
  os.writeInt32(1, this.iItemCount);
  os.writeInt64(2, this.lPresenterUid);
  os.writeInt64(3, this.lSenderUid);
  os.writeString(4, this.sPresenterNick);
  os.writeString(5, this.sSenderNick);
  os.writeInt64(6, this.lRoomId);
  os.writeInt32(7, this.iComboScore);
  os.writeInt32(8, this.iPayType);
  os.writeMap(9, this.mUpgradeEffect);
  os.writeInt32(10, this.iPlay);
  os.writeString(11, this.sAlise);
  os.writeInt32(12, this.iJumpType);
  os.writeString(13, this.sJumpUrl);
  os.writeString(14, this.sSenderAvatarUrl);
  os.writeString(15, this.sRecipientNick);
  os.writeInt64(16, this.lRecipientId);
  os.writeMap(17, this.mBigAnimEffectText);
  os.writeMap(18, this.mBigAnimEffectImage);
  os.writeInt32(19, this.iGiftEffectSource);
  os.writeStruct(20, this.tEffectID);
  os.writeVector(21, this.vAvatarUrls);
  os.writeStruct(22, this.tRichText);
};
SendGiftEffectBroadcastPacket.prototype.readFrom = function (is) {
  this.iItemType = is.readInt32(0, false, this.iItemType);
  this.iItemCount = is.readInt32(1, false, this.iItemCount);
  this.lPresenterUid = is.readInt64(2, false, this.lPresenterUid);
  this.lSenderUid = is.readInt64(3, false, this.lSenderUid);
  this.sPresenterNick = is.readString(4, false, this.sPresenterNick);
  this.sSenderNick = is.readString(5, false, this.sSenderNick);
  this.lRoomId = is.readInt64(6, false, this.lRoomId);
  this.iComboScore = is.readInt32(7, false, this.iComboScore);
  this.iPayType = is.readInt32(8, false, this.iPayType);
  this.mUpgradeEffect = is.readMap(9, false, this.mUpgradeEffect);
  this.iPlay = is.readInt32(10, false, this.iPlay);
  this.sAlise = is.readString(11, false, this.sAlise);
  this.iJumpType = is.readInt32(12, false, this.iJumpType);
  this.sJumpUrl = is.readString(13, false, this.sJumpUrl);
  this.sSenderAvatarUrl = is.readString(14, false, this.sSenderAvatarUrl);
  this.sRecipientNick = is.readString(15, false, this.sRecipientNick);
  this.lRecipientId = is.readInt64(16, false, this.lRecipientId);
  this.mBigAnimEffectText = is.readMap(17, false, this.mBigAnimEffectText);
  this.mBigAnimEffectImage = is.readMap(18, false, this.mBigAnimEffectImage);
  this.iGiftEffectSource = is.readInt32(19, false, this.iGiftEffectSource);
  this.tEffectID = is.readStruct(20, false, this.tEffectID);
  this.vAvatarUrls = is.readVector(21, false, this.vAvatarUrls);
  this.tRichText = is.readStruct(22, false, this.tRichText);
};
Nimo.SendGiftEffectBroadcastPacket = SendGiftEffectBroadcastPacket;
export default SendGiftEffectBroadcastPacket;
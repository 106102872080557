import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ESessionType = {
  EAssit: 1,
  ESysNotify: 2,
  ENormalIM: 3,
  EStranger: 4,
  EGroup: 5,
  EOfficialComm: 6,
  EOfficial: 7,
  EVipService: 8
};
Nimo.ESessionType = ESessionType;
export default ESessionType;
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import roomManagerWupModel from '@client/common/models/wup/roomManagerWupModel';
import meetingSocketModel from '@client/common/models/socket/meetingSocketModel';
import { useEffect, useState } from 'react';
var OPER_TYPE_ADD = 1;
var OPER_TYPE_FIRE = 2;
export var useIsSelfAdmin = function useIsSelfAdmin(roomId, uid) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isAdmin = _useState2[0],
    setIsAdmin = _useState2[1];
  useEffect(function () {
    if (roomId && uid) {
      var unmounted = false;
      roomManagerWupModel.checkRoomManager(roomId, uid).then(function (x) {
        var _ref = x || {},
          bIsManager = _ref.bIsManager,
          lRoomId = _ref.lRoomId,
          lUid = _ref.lUid;
        if (!unmounted && +lRoomId === +roomId && +lUid === +uid) {
          setIsAdmin(bIsManager);
        }
      });
      var handler = function handler(_ref2) {
        var iOperType = _ref2.iOperType,
          lRoomId = _ref2.lRoomId,
          lUid = _ref2.lUid;
        if (+lRoomId === +roomId && +lUid === +uid) {
          if (iOperType === OPER_TYPE_ADD) {
            setIsAdmin(true);
          } else if (iOperType === OPER_TYPE_FIRE) {
            setIsAdmin(false);
          }
        }
      };
      meetingSocketModel.on('UserSetRoomManager', handler);
      return function () {
        unmounted = true;
        meetingSocketModel.off('UserSetRoomManager', handler);
      };
    }
    return undefined;
  }, [roomId, uid]);
  return isAdmin;
};
import TafMessageSocketModel from '../TafMessageSocketModel';
import EURI from '@client/jce/Nimo/EURI';
import FingerGuessingGameRsp from '@client/jce/Nimo/FingerGuessingGameRsp';
import DiceGameRsp from '@client/jce/Nimo/DiceGameRsp';
var EUriSundryDiceGame = EURI.EUriSundryDiceGame,
  EUriSundryFingerGuessingGame = EURI.EUriSundryFingerGuessingGame;
export { EUriSundryDiceGame, EUriSundryFingerGuessingGame };
export default new TafMessageSocketModel({
  lazyInitSocket: true,
  broadcasts: {
    DiceGameRsp: [EUriSundryDiceGame, DiceGameRsp],
    FingerGuessingGameRsp: [EUriSundryFingerGuessingGame, FingerGuessingGameRsp]
  }
});
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var UserLevelObject = function UserLevelObject() {
  this.level = 0;
};
UserLevelObject.prototype._clone = function () {
  return new Nimo.UserLevelObject();
};
UserLevelObject.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserLevelObject.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserLevelObject.prototype.writeTo = function (os) {
  os.writeInt32(0, this.level);
};
UserLevelObject.prototype.readFrom = function (is) {
  this.level = is.readInt32(0, false, this.level);
};
Nimo.UserLevelObject = UserLevelObject;
export default UserLevelObject;
import { getGiftInfoByGiftMsg } from '@client/common/services/giftMgr';
import { useSelector, shallowEqual } from 'react-redux';
import { useCallback } from 'react';

/**
 * 创建礼物信息获取函数
 * @returns {(msg: any) => object | null}
 */
export function useGetGiftInfo() {
  var _useSelector = useSelector(function (state) {
      var _ref = (state.home || state).room || {},
        list = _ref.giftList,
        loaded = _ref.giftListLoaded;
      return {
        giftList: list,
        giftListLoaded: loaded
      };
    }, shallowEqual),
    giftList = _useSelector.giftList,
    giftListLoaded = _useSelector.giftListLoaded;
  return useCallback(function (msg) {
    if (!giftListLoaded) return null;
    return getGiftInfoByGiftMsg(giftList || [], msg);
  }, [giftList, giftListLoaded]);
}
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MsgNoticeType = function MsgNoticeType() {
  this.sText = "";
};
MsgNoticeType.prototype._clone = function () {
  return new Nimo.MsgNoticeType();
};
MsgNoticeType.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgNoticeType.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgNoticeType.prototype.writeTo = function (os) {
  os.writeString(0, this.sText);
};
MsgNoticeType.prototype.readFrom = function (is) {
  this.sText = is.readString(0, false, this.sText);
};
Nimo.MsgNoticeType = MsgNoticeType;
export default MsgNoticeType;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var PKRankInfo = function PKRankInfo() {
  this.iStatus = 0;
  this.iSeason = 0;
  this.sSeasonName = "";
  this.iGrade = 1;
  this.sGradeName = "";
  this.iStage = 1;
  this.sStageName = "";
  this.sRankName = "";
  this.iNowStar = 0;
  this.iNextStageStar = 0;
  this.sGradeIcon = "";
  this.iTotalStar = 0;
  this.iRanking = 0;
  this.iRankEntraceAvailable = 0;
  this.mRankName = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.lNowStarPoint = 0;
  this.lNextStarPoint = 0;
  this.lTotalPoint = 0;
};
PKRankInfo.prototype._clone = function () {
  return new Nimo.PKRankInfo();
};
PKRankInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PKRankInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PKRankInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iStatus);
  os.writeInt32(1, this.iSeason);
  os.writeString(2, this.sSeasonName);
  os.writeInt32(3, this.iGrade);
  os.writeString(4, this.sGradeName);
  os.writeInt32(5, this.iStage);
  os.writeString(6, this.sStageName);
  os.writeString(7, this.sRankName);
  os.writeInt32(8, this.iNowStar);
  os.writeInt32(9, this.iNextStageStar);
  os.writeString(10, this.sGradeIcon);
  os.writeInt32(11, this.iTotalStar);
  os.writeInt32(12, this.iRanking);
  os.writeInt32(13, this.iRankEntraceAvailable);
  os.writeMap(14, this.mRankName);
  os.writeInt64(15, this.lNowStarPoint);
  os.writeInt64(16, this.lNextStarPoint);
  os.writeInt64(17, this.lTotalPoint);
};
PKRankInfo.prototype.readFrom = function (is) {
  this.iStatus = is.readInt32(0, false, this.iStatus);
  this.iSeason = is.readInt32(1, false, this.iSeason);
  this.sSeasonName = is.readString(2, false, this.sSeasonName);
  this.iGrade = is.readInt32(3, false, this.iGrade);
  this.sGradeName = is.readString(4, false, this.sGradeName);
  this.iStage = is.readInt32(5, false, this.iStage);
  this.sStageName = is.readString(6, false, this.sStageName);
  this.sRankName = is.readString(7, false, this.sRankName);
  this.iNowStar = is.readInt32(8, false, this.iNowStar);
  this.iNextStageStar = is.readInt32(9, false, this.iNextStageStar);
  this.sGradeIcon = is.readString(10, false, this.sGradeIcon);
  this.iTotalStar = is.readInt32(11, false, this.iTotalStar);
  this.iRanking = is.readInt32(12, false, this.iRanking);
  this.iRankEntraceAvailable = is.readInt32(13, false, this.iRankEntraceAvailable);
  this.mRankName = is.readMap(14, false, this.mRankName);
  this.lNowStarPoint = is.readInt64(15, false, this.lNowStarPoint);
  this.lNextStarPoint = is.readInt64(16, false, this.lNextStarPoint);
  this.lTotalPoint = is.readInt64(17, false, this.lTotalPoint);
};
Nimo.PKRankInfo = PKRankInfo;
export default PKRankInfo;
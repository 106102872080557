import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EMsgGroupMemberChangeType = {
  EMSGGROUP_JOIN: 1,
  EMSGGROUP_EXIT: 2,
  EMSGGROUP_KICK: 3,
  EMSGGROUP_INIITED: 4,
  EMSGGROUP_TEXT: 5
};
Nimo.EMsgGroupMemberChangeType = EMsgGroupMemberChangeType;
export default EMsgGroupMemberChangeType;
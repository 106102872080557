import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './AnimationContent';
var RoomAnimationNotice = function RoomAnimationNotice() {
  this.tAdrAnimContent = new Nimo.AnimationContent();
  this.tIosAnimContent = new Nimo.AnimationContent();
  this.tWebAnimContent = new Nimo.AnimationContent();
  this.iType = 0;
  this.sData = "";
  this.lRoomId = 0;
};
RoomAnimationNotice.prototype._clone = function () {
  return new Nimo.RoomAnimationNotice();
};
RoomAnimationNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomAnimationNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomAnimationNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tAdrAnimContent);
  os.writeStruct(1, this.tIosAnimContent);
  os.writeStruct(2, this.tWebAnimContent);
  os.writeInt32(3, this.iType);
  os.writeString(4, this.sData);
  os.writeInt64(5, this.lRoomId);
};
RoomAnimationNotice.prototype.readFrom = function (is) {
  this.tAdrAnimContent = is.readStruct(0, false, this.tAdrAnimContent);
  this.tIosAnimContent = is.readStruct(1, false, this.tIosAnimContent);
  this.tWebAnimContent = is.readStruct(2, false, this.tWebAnimContent);
  this.iType = is.readInt32(3, false, this.iType);
  this.sData = is.readString(4, false, this.sData);
  this.lRoomId = is.readInt64(5, false, this.lRoomId);
};
Nimo.RoomAnimationNotice = RoomAnimationNotice;
export default RoomAnimationNotice;
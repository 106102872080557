import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './SenderInfo';
var ChatBotMessageNotice = function ChatBotMessageNotice() {
  this.tUserInfo = new Nimo.SenderInfo();
  this.sMessage = "";
  this.lRoomId = 0;
};
ChatBotMessageNotice.prototype._clone = function () {
  return new Nimo.ChatBotMessageNotice();
};
ChatBotMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChatBotMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChatBotMessageNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserInfo);
  os.writeString(1, this.sMessage);
  os.writeInt64(2, this.lRoomId);
};
ChatBotMessageNotice.prototype.readFrom = function (is) {
  this.tUserInfo = is.readStruct(0, false, this.tUserInfo);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
};
Nimo.ChatBotMessageNotice = ChatBotMessageNotice;
export default ChatBotMessageNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AuditPunishMuteNotice = function AuditPunishMuteNotice() {
  this.anchorId = 0;
  this.roomId = 0;
  this.mute = 0;
  this.muteSeconds = 0;
};
AuditPunishMuteNotice.prototype._clone = function () {
  return new Nimo.AuditPunishMuteNotice();
};
AuditPunishMuteNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AuditPunishMuteNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AuditPunishMuteNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.anchorId);
  os.writeInt64(1, this.roomId);
  os.writeInt64(2, this.mute);
  os.writeInt32(3, this.muteSeconds);
};
AuditPunishMuteNotice.prototype.readFrom = function (is) {
  this.anchorId = is.readInt64(0, false, this.anchorId);
  this.roomId = is.readInt64(1, false, this.roomId);
  this.mute = is.readInt64(2, false, this.mute);
  this.muteSeconds = is.readInt32(3, false, this.muteSeconds);
};
Nimo.AuditPunishMuteNotice = AuditPunishMuteNotice;
export default AuditPunishMuteNotice;
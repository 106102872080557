import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import confirm from './confirm';
import DashboardButton from '@client/common/components/DashboardButton';
import './index';
import './style/nimo-dashboard-confirm';
export default function dashboardConfirm(props) {
  var config = _objectSpread(_objectSpread({
    prefixCls: 'nimo-dashboard-confirm',
    type: 'confirm',
    width: 510,
    okCancel: true,
    useModalTitle: true,
    primaryButtonFirst: true,
    cancelType: 'secondary-ghost',
    buttonComponent: DashboardButton
  }, props), {}, {
    title: props.title || "\u200B"
  });
  return confirm(config);
}
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './MeetingSeat';
import './MeetingRoomInfo';
var MeetingInfo = function MeetingInfo() {
  this.iType = 0;
  this.sSessionID = "";
  this.vSeat = new Taf.Vector(new Nimo.MeetingSeat());
  this.bWaitEmpty = true;
  this.iMeetingStatus = 0;
  this.lSeqID = 0;
  this.iAnchorMicStatus = 0;
  this.tRoomInfo = new Nimo.MeetingRoomInfo();
  this.lAnchorUID = 0;
  this.tHostSeat = new Nimo.MeetingSeat();
  this.lRoomID = 0;
};
MeetingInfo.prototype._clone = function () {
  return new Nimo.MeetingInfo();
};
MeetingInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeString(1, this.sSessionID);
  os.writeVector(2, this.vSeat);
  os.writeBoolean(3, this.bWaitEmpty);
  os.writeInt32(4, this.iMeetingStatus);
  os.writeInt64(5, this.lSeqID);
  os.writeInt32(6, this.iAnchorMicStatus);
  os.writeStruct(8, this.tRoomInfo);
  os.writeInt64(9, this.lAnchorUID);
  os.writeStruct(10, this.tHostSeat);
  os.writeInt64(11, this.lRoomID);
};
MeetingInfo.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.sSessionID = is.readString(1, false, this.sSessionID);
  this.vSeat = is.readVector(2, false, this.vSeat);
  this.bWaitEmpty = is.readBoolean(3, false, this.bWaitEmpty);
  this.iMeetingStatus = is.readInt32(4, false, this.iMeetingStatus);
  this.lSeqID = is.readInt64(5, false, this.lSeqID);
  this.iAnchorMicStatus = is.readInt32(6, false, this.iAnchorMicStatus);
  this.tRoomInfo = is.readStruct(8, false, this.tRoomInfo);
  this.lAnchorUID = is.readInt64(9, false, this.lAnchorUID);
  this.tHostSeat = is.readStruct(10, false, this.tHostSeat);
  this.lRoomID = is.readInt64(11, false, this.lRoomID);
};
Nimo.MeetingInfo = MeetingInfo;
export default MeetingInfo;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var FortuneGiftStreamerContent = function FortuneGiftStreamerContent() {
  this.sUrl = "";
};
FortuneGiftStreamerContent.prototype._clone = function () {
  return new Nimo.FortuneGiftStreamerContent();
};
FortuneGiftStreamerContent.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FortuneGiftStreamerContent.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FortuneGiftStreamerContent.prototype.writeTo = function (os) {
  os.writeString(0, this.sUrl);
};
FortuneGiftStreamerContent.prototype.readFrom = function (is) {
  this.sUrl = is.readString(0, false, this.sUrl);
};
Nimo.FortuneGiftStreamerContent = FortuneGiftStreamerContent;
export default FortuneGiftStreamerContent;
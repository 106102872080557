import { setCCountryList, setCCountry } from './actions';
import { configModel } from '@client/common/models/api/config';
import { getCCountry } from '../utils/cookieFn';
export var getCCountryListAsync = function getCCountryListAsync() {
  return function (dispatch, getState) {
    var s = getState().base.cCountryList;
    if (!s) {
      return configModel.getCCountryList().then(function (cCountryList) {
        dispatch(setCCountryList(cCountryList));
        return cCountryList;
      }).catch(function () {});
    }
    return Promise.resolve(s);
  };
};
export var setCCountrySync = function setCCountrySync() {
  return function (dispatch, getState) {
    var s = getState().base.cCountry;
    var cCountry = getCCountry();
    if (cCountry !== s) {
      dispatch(setCCountry(cCountry));
    }
  };
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftWallGiftUnlockPublicScreenNoticeRsp = function GiftWallGiftUnlockPublicScreenNoticeRsp() {
  this.msgMap = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.jumpUrl = "";
};
GiftWallGiftUnlockPublicScreenNoticeRsp.prototype._clone = function () {
  return new Nimo.GiftWallGiftUnlockPublicScreenNoticeRsp();
};
GiftWallGiftUnlockPublicScreenNoticeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftWallGiftUnlockPublicScreenNoticeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftWallGiftUnlockPublicScreenNoticeRsp.prototype.writeTo = function (os) {
  os.writeMap(0, this.msgMap);
  os.writeString(1, this.jumpUrl);
};
GiftWallGiftUnlockPublicScreenNoticeRsp.prototype.readFrom = function (is) {
  this.msgMap = is.readMap(0, false, this.msgMap);
  this.jumpUrl = is.readString(1, false, this.jumpUrl);
};
Nimo.GiftWallGiftUnlockPublicScreenNoticeRsp = GiftWallGiftUnlockPublicScreenNoticeRsp;
export default GiftWallGiftUnlockPublicScreenNoticeRsp;
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var GetFollowCountRsp = function GetFollowCountRsp() {
  this.count = 0;
};
GetFollowCountRsp.prototype._clone = function () {
  return new NimoBuss.GetFollowCountRsp();
};
GetFollowCountRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFollowCountRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFollowCountRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.count);
};
GetFollowCountRsp.prototype.readFrom = function (is) {
  this.count = is.readInt32(0, false, this.count);
};
NimoBuss.GetFollowCountRsp = GetFollowCountRsp;
export default GetFollowCountRsp;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var NoticeMcRequest = function NoticeMcRequest() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.sName = "";
  this.sImageUrl = "";
  this.iSex = 0;
  this.sCountry = "";
};
NoticeMcRequest.prototype._clone = function () {
  return new Nimo.NoticeMcRequest();
};
NoticeMcRequest.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NoticeMcRequest.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NoticeMcRequest.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeString(2, this.sName);
  os.writeString(3, this.sImageUrl);
  os.writeInt32(4, this.iSex);
  os.writeString(5, this.sCountry);
};
NoticeMcRequest.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.sName = is.readString(2, false, this.sName);
  this.sImageUrl = is.readString(3, false, this.sImageUrl);
  this.iSex = is.readInt32(4, false, this.iSex);
  this.sCountry = is.readString(5, false, this.sCountry);
};
Nimo.NoticeMcRequest = NoticeMcRequest;
export default NoticeMcRequest;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './ItemTallyUserInfo';
var ItemTallyRecord = function ItemTallyRecord() {
  this.iItemID = 0;
  this.iItemCount = 0;
  this.iItemTargetCount = 0;
  this.sTitle = "";
  this.lAddTimestamp = 0;
  this.lFinishTimestamp = 0;
  this.iMode = 0;
  this.iHide = 0;
  this.iIsEnd = 0;
  this.sPromise = "";
  this.iItemInitialCount = 0;
  this.iDurationTime = 0;
  this.iGiftId = 0;
  this.sGiftIcon = "";
  this.vContributerInfo = new Taf.Vector(new Nimo.ItemTallyUserInfo());
  this.vFollowerInfo = new Taf.Vector(new Nimo.ItemTallyUserInfo());
  this.sGiftName = "";
  this.iLeftTime = 0;
  this.sUniqID = "";
};
ItemTallyRecord.prototype._clone = function () {
  return new Nimo.ItemTallyRecord();
};
ItemTallyRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ItemTallyRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ItemTallyRecord.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iItemID);
  os.writeInt32(1, this.iItemCount);
  os.writeInt32(2, this.iItemTargetCount);
  os.writeString(3, this.sTitle);
  os.writeInt64(4, this.lAddTimestamp);
  os.writeInt64(5, this.lFinishTimestamp);
  os.writeInt32(6, this.iMode);
  os.writeInt32(7, this.iHide);
  os.writeInt32(8, this.iIsEnd);
  os.writeString(9, this.sPromise);
  os.writeInt32(10, this.iItemInitialCount);
  os.writeInt32(11, this.iDurationTime);
  os.writeInt32(12, this.iGiftId);
  os.writeString(13, this.sGiftIcon);
  os.writeVector(14, this.vContributerInfo);
  os.writeVector(15, this.vFollowerInfo);
  os.writeString(16, this.sGiftName);
  os.writeInt32(17, this.iLeftTime);
  os.writeString(18, this.sUniqID);
};
ItemTallyRecord.prototype.readFrom = function (is) {
  this.iItemID = is.readInt32(0, false, this.iItemID);
  this.iItemCount = is.readInt32(1, false, this.iItemCount);
  this.iItemTargetCount = is.readInt32(2, false, this.iItemTargetCount);
  this.sTitle = is.readString(3, false, this.sTitle);
  this.lAddTimestamp = is.readInt64(4, false, this.lAddTimestamp);
  this.lFinishTimestamp = is.readInt64(5, false, this.lFinishTimestamp);
  this.iMode = is.readInt32(6, false, this.iMode);
  this.iHide = is.readInt32(7, false, this.iHide);
  this.iIsEnd = is.readInt32(8, false, this.iIsEnd);
  this.sPromise = is.readString(9, false, this.sPromise);
  this.iItemInitialCount = is.readInt32(10, false, this.iItemInitialCount);
  this.iDurationTime = is.readInt32(11, false, this.iDurationTime);
  this.iGiftId = is.readInt32(12, false, this.iGiftId);
  this.sGiftIcon = is.readString(13, false, this.sGiftIcon);
  this.vContributerInfo = is.readVector(14, false, this.vContributerInfo);
  this.vFollowerInfo = is.readVector(15, false, this.vFollowerInfo);
  this.sGiftName = is.readString(16, false, this.sGiftName);
  this.iLeftTime = is.readInt32(17, false, this.iLeftTime);
  this.sUniqID = is.readString(18, false, this.sUniqID);
};
Nimo.ItemTallyRecord = ItemTallyRecord;
export default ItemTallyRecord;
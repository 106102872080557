import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var CharmValueRsq = function CharmValueRsq() {
  this.lAnchorUid = 0;
  this.lVersion = -1;
  this.mGuestCharmValue = new Taf.Map(new Taf.INT64(), new Taf.INT64());
  this.iOperations = 0;
  this.mHatInfo = new Taf.Map(new Taf.INT64(), new Taf.STRING());
};
CharmValueRsq.prototype._clone = function () {
  return new Nimo.CharmValueRsq();
};
CharmValueRsq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CharmValueRsq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CharmValueRsq.prototype.writeTo = function (os) {
  os.writeInt64(1, this.lAnchorUid);
  os.writeInt64(2, this.lVersion);
  os.writeMap(3, this.mGuestCharmValue);
  os.writeInt32(4, this.iOperations);
  os.writeMap(5, this.mHatInfo);
};
CharmValueRsq.prototype.readFrom = function (is) {
  this.lAnchorUid = is.readInt64(1, false, this.lAnchorUid);
  this.lVersion = is.readInt64(2, false, this.lVersion);
  this.mGuestCharmValue = is.readMap(3, false, this.mGuestCharmValue);
  this.iOperations = is.readInt32(4, false, this.iOperations);
  this.mHatInfo = is.readMap(5, false, this.mHatInfo);
};
Nimo.CharmValueRsq = CharmValueRsq;
export default CharmValueRsq;
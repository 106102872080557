import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var MatchAnchorInfo = function MatchAnchorInfo() {
  this.udbUserId = 0;
  this.matchStatus = 0;
  this.matchId = 0;
  this.title = "";
  this.scheduleId = 0;
  this.scheduleName = "";
  this.matchRoomId = 0;
};
MatchAnchorInfo.prototype._clone = function () {
  return new NimoBuss.MatchAnchorInfo();
};
MatchAnchorInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchAnchorInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchAnchorInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeInt32(1, this.matchStatus);
  os.writeInt64(2, this.matchId);
  os.writeString(3, this.title);
  os.writeInt64(4, this.scheduleId);
  os.writeString(5, this.scheduleName);
  os.writeInt64(6, this.matchRoomId);
};
MatchAnchorInfo.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.matchStatus = is.readInt32(1, false, this.matchStatus);
  this.matchId = is.readInt64(2, false, this.matchId);
  this.title = is.readString(3, false, this.title);
  this.scheduleId = is.readInt64(4, false, this.scheduleId);
  this.scheduleName = is.readString(5, false, this.scheduleName);
  this.matchRoomId = is.readInt64(6, false, this.matchRoomId);
};
NimoBuss.MatchAnchorInfo = MatchAnchorInfo;
export default MatchAnchorInfo;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var PresenterInfo = function PresenterInfo() {
  this.lPresenterId = 0;
  this.lRoomId = 0;
  this.sName = "";
  this.iSex = 0;
  this.sImageUrl = "";
  this.sCountry = "";
  this.sStreamKey = 0;
  this.sAlise = "";
};
PresenterInfo.prototype._clone = function () {
  return new Nimo.PresenterInfo();
};
PresenterInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PresenterInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PresenterInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lPresenterId);
  os.writeInt64(1, this.lRoomId);
  os.writeString(2, this.sName);
  os.writeInt32(3, this.iSex);
  os.writeString(4, this.sImageUrl);
  os.writeString(5, this.sCountry);
  os.writeUInt32(6, this.sStreamKey);
  os.writeString(7, this.sAlise);
};
PresenterInfo.prototype.readFrom = function (is) {
  this.lPresenterId = is.readInt64(0, false, this.lPresenterId);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.sName = is.readString(2, false, this.sName);
  this.iSex = is.readInt32(3, false, this.iSex);
  this.sImageUrl = is.readString(4, false, this.sImageUrl);
  this.sCountry = is.readString(5, false, this.sCountry);
  this.sStreamKey = is.readUInt32(6, false, this.sStreamKey);
  this.sAlise = is.readString(7, false, this.sAlise);
};
Nimo.PresenterInfo = PresenterInfo;
export default PresenterInfo;
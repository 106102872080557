export default {
  MODE: {
    ROOM: 'room',
    CLIP: 'clip',
    EMBED: 'embed',
    INDEX: 'index',
    LIST: 'list',
    DASHBOARD: 'dashboard',
    CLIP_CREATE: 'clipCreate',
    NEW_DASHBOARD: 'new-dashboard',
    MKT_ROOM: 'mkt_room',
    USER_POST: 'user-post',
    SIMPLE: 'simple' // 只自动静音循环播放视频
  },
  TYPE: {
    LIVE: 'live',
    VIDEO: 'video'
  },
  VIDEO_STATUS: {
    UPDATING: 0,
    // 上传中
    UDPATE_FAILED: 1,
    // 上传失败
    REVIEWING: 2,
    // 审核中
    REVIEW_FAILED: 3,
    // 审核失败
    NOT_PUBLISHED: 4,
    // 未发布
    PUBLISHED: 5,
    // 已发布
    NOT_EXISTED: 6 // 已下架
  },
  RATE_MAP: {
    2: '720',
    3: '480',
    4: '360',
    5: '240',
    6: '1080'
  },
  RATE_MAP_REVERT: {
    720: 2,
    480: 3,
    360: 4,
    240: 5,
    1080: 6
  },
  RATE_ORDER: [1, 6, 2, 3, 4, 5, 'auto'],
  CDN_MAP: {
    1: 'ws',
    // 网宿
    2: 'tx',
    // 腾讯
    3: 'al',
    // 阿里
    4: 'ms',
    // 微软
    5: 'aws',
    // AWS
    6: 'gg',
    // Google
    7: 'cf',
    // cloudflare
    9: 'ag',
    // 声网
    10: 'srs',
    // 自己的源站
    11: 'amz',
    // 亚马逊s3
    12: 'vpp',
    // 视频处理中台 VideoProcessPlatform
    13: 'hy' // 虎牙
  },
  HY_CDN_MAP: {
    1: 1,
    // 网宿
    3: 3,
    // 阿里
    5: 2,
    // 腾讯
    9: 6,
    // Google
    10: 7 // cloudflare
  },
  MEDIA_TYPE_MAP: {
    1: 'flv',
    2: 'hls',
    3: 'rtmp',
    10: 'mp4'
  },
  MIMETYPE_MAP: {
    1: 'video/x-flv',
    2: 'application/x-mpegurl',
    10: 'video/mp4'
  },
  MEDIA_TYPE_MAP_INVERT: {
    flv: 1,
    m3u8: 2,
    rtmp: 3,
    mp4: 10
  },
  RATE_IN_DECREASE: [1, 6, 2, 3, 4, 5],
  SIZE_MAP: {
    PIP: 'pip',
    // 画中画
    NORMAL: 'normal',
    // 正常模式
    THEATER: 'theater',
    // 剧场模式
    FULLSCREEN: 'fullscreen' // 全屏
  },
  MAX_STAT_LEN: 1000,
  LOCAL_STORAGE_VOLUME_KEY: 'nimo_volume_key',
  // 音量大小
  LOCAL_STORAGE_MUTE_KEY: 'nimo_mute_key',
  // 是否静音
  LOCAL_STORAGE_RATE_KEY: 'nimo_rate_key',
  // 直播记忆清晰度
  LOCAL_STORAGE_VIDEO_RATE_KEY: 'nimo_video_rate_key',
  // 点播记忆清晰度
  MAX_MEMORIZED_RATE: 3 // 最大记忆清晰度
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RichText';
var SystemChatMessageNotice = function SystemChatMessageNotice() {
  this.tSystemMessage = new Nimo.RichText();
  this.lAnchorId = 0;
  this.iMessageSource = 0;
  this.iJumpType = 0;
  this.sJumpUrl = "";
  this.sJumpData = new Taf.BinBuffer();
};
SystemChatMessageNotice.prototype._clone = function () {
  return new Nimo.SystemChatMessageNotice();
};
SystemChatMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SystemChatMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SystemChatMessageNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tSystemMessage);
  os.writeInt64(1, this.lAnchorId);
  os.writeInt32(2, this.iMessageSource);
  os.writeInt32(3, this.iJumpType);
  os.writeString(4, this.sJumpUrl);
  os.writeBytes(6, this.sJumpData);
};
SystemChatMessageNotice.prototype.readFrom = function (is) {
  this.tSystemMessage = is.readStruct(0, false, this.tSystemMessage);
  this.lAnchorId = is.readInt64(1, false, this.lAnchorId);
  this.iMessageSource = is.readInt32(2, false, this.iMessageSource);
  this.iJumpType = is.readInt32(3, false, this.iJumpType);
  this.sJumpUrl = is.readString(4, false, this.sJumpUrl);
  this.sJumpData = is.readBytes(6, false, this.sJumpData);
};
Nimo.SystemChatMessageNotice = SystemChatMessageNotice;
export default SystemChatMessageNotice;
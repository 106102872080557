import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MsgPicType = function MsgPicType() {
  this.sPic = "";
  this.iStatus = 0;
};
MsgPicType.prototype._clone = function () {
  return new Nimo.MsgPicType();
};
MsgPicType.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgPicType.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgPicType.prototype.writeTo = function (os) {
  os.writeString(0, this.sPic);
  os.writeInt32(1, this.iStatus);
};
MsgPicType.prototype.readFrom = function (is) {
  this.sPic = is.readString(0, false, this.sPic);
  this.iStatus = is.readInt32(1, false, this.iStatus);
};
Nimo.MsgPicType = MsgPicType;
export default MsgPicType;
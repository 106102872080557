import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var RoomManagerNotice = function RoomManagerNotice() {
  this.iOperType = 0;
  this.lRoomId = 0;
  this.lUid = 0;
  this.sUserName = "";
};
RoomManagerNotice.prototype._clone = function () {
  return new Nimo.RoomManagerNotice();
};
RoomManagerNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomManagerNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomManagerNotice.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iOperType);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lUid);
  os.writeString(3, this.sUserName);
};
RoomManagerNotice.prototype.readFrom = function (is) {
  this.iOperType = is.readInt32(0, false, this.iOperType);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lUid = is.readInt64(2, false, this.lUid);
  this.sUserName = is.readString(3, false, this.sUserName);
};
Nimo.RoomManagerNotice = RoomManagerNotice;
export default RoomManagerNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RichText';
var PayChatRichTextItem = function PayChatRichTextItem() {
  this.tList = new Nimo.RichText();
  this.tTitle = new Nimo.RichText();
  this.tContent = new Nimo.RichText();
};
PayChatRichTextItem.prototype._clone = function () {
  return new Nimo.PayChatRichTextItem();
};
PayChatRichTextItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PayChatRichTextItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PayChatRichTextItem.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tList);
  os.writeStruct(1, this.tTitle);
  os.writeStruct(2, this.tContent);
};
PayChatRichTextItem.prototype.readFrom = function (is) {
  this.tList = is.readStruct(0, false, this.tList);
  this.tTitle = is.readStruct(1, false, this.tTitle);
  this.tContent = is.readStruct(2, false, this.tContent);
};
Nimo.PayChatRichTextItem = PayChatRichTextItem;
export default PayChatRichTextItem;
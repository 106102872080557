// import React from 'react';
// import ReactDOM from 'react-dom';
import ReactController from './main';
import './style';
export default ReactController;
// class Controller {
//   constructor(player, playerConfig, options) {
//     this.parent = player;
//     this.config = playerConfig;
//     this.options = options;
//     this.initElement();
//     this.initView();
//   }

//   initElement() {
//     const { containerId } = this.parent;
//     const container = document.getElementById(containerId);
//     const div = document.createElement('div');
//     div.id = 'nimo-player_controller';
//     this.id = div.id;
//     container.appendChild(div);
//   }

//   initView() {
//     ReactDOM.render(
//       <ReactController 
//         parent={this.parent}
//         config={this.config}
//         {...this.options}
//       />,
//       document.getElementById(this.id)
//     );
//   }
// }

// export default Controller;
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ActBadgePK, ActBadgeLottery, ActBadgeGuessing } from './constants';
var priorityList = [{
  type: ActBadgePK,
  prop: 'microPKStatus'
}, {
  type: ActBadgeLottery,
  prop: 'isLottery'
}, {
  type: ActBadgeGuessing,
  prop: 'isGuessing'
}];
export function getSingleBadge(data) {
  var input = data || {};
  var matched = priorityList.find(function (item) {
    return input[item.prop] && Number(input[item.prop]) === 1;
  });
  if (matched) {
    return [_objectSpread(_objectSpread({}, matched), {}, {
      condition: true
    })];
  } else {
    return undefined;
  }
}
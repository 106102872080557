import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Logger from '@client/common/utils/logger';
import starShowWupModel, { ELinkMicType } from '@client/common/models/wup/starShowWupModel';
import starShowSocketModel, { EUriLinkMicUserInfoChange, EUriLinkMicClientDataChange, EUriLinkMicStatusChange } from '@client/common/models/socket/starShowSocketModel';
import { updateLinkMicSeats, updateLinkMicHost, updateShowRecipient } from '@desktop/src/common/models/home/actions';
import meetingWupModel from '@client/common/models/wup/meetingWupModel';
import meetingSocketModel from '@client/common/models/socket/meetingSocketModel';
import Message from '@client/common/components/Message';
// import { LINK_MIC_TYPE } from './constants';

var kLINKMIC_AUDIENCE_NEW = ELinkMicType.kLINKMIC_AUDIENCE_NEW,
  kLINKMIC_TEAM = ELinkMicType.kLINKMIC_TEAM;
export var useLinkMicList = function useLinkMicList(roomInfo) {
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    loading = _useState2[0],
    setLoading = _useState2[1];
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    linkMicList = _useState4[0],
    setLinkMicList = _useState4[1];
  var _useState5 = useState(0),
    _useState6 = _slicedToArray(_useState5, 2),
    linkMicType = _useState6[0],
    setLinkMicType = _useState6[1]; // ELinkMicType
  var _useState7 = useState(0),
    _useState8 = _slicedToArray(_useState7, 2),
    linkMicNewType = _useState8[0],
    setLinkMicNewType = _useState8[1]; // 新连麦模式的连麦类型，3 普通模式（观众/主播），7 聊天室模式，9 派对模式。0即旧连麦模式
  // const [isFixedLayout, setIsFixedLayout] = useState(0); // 派对模式，是否固定九宫格布局

  // 连麦列表变化（旧的主播连麦）
  var handleLinkMicListInfo = function handleLinkMicListInfo(_ref) {
    var vUsers = _ref.vUsers;
    // Logger.log('handleLinkMicListInfo', { vUsers });
    var validUsers = (vUsers === null || vUsers === void 0 ? void 0 : vUsers.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.lUID) > 0;
    })) || []; // 过滤无效数据
    setLinkMicList(validUsers);
  };
  // 用户状态变更通知（新连麦）
  var handleLinkMicUserInfoChange = function handleLinkMicUserInfoChange(_ref2) {
    var mUserInfo = _ref2.mUserInfo;
    // Logger.log('handleLinkMicUserInfoChange', { mUserInfo });
    setLinkMicList(mUserInfo);
  };
  // mpClientData 变更通知（新连麦）
  var handleLinkMicLinkMicClientDataChange = function handleLinkMicLinkMicClientDataChange(_ref3) {
    var iLinkMicType = _ref3.iLinkMicType,
      mData = _ref3.mData;
    // Logger.log('handleLinkMicLinkMicClientDataChange', { iLinkMicType, mData });
    var isNewType = iLinkMicType === kLINKMIC_AUDIENCE_NEW;
    var audience_link_mode = mData.audience_link_mode;
    // const { audience_link_mode, is_fixed_layout } = mData;
    var type = isNewType ? +audience_link_mode : 0;
    setLinkMicType(iLinkMicType);
    setLinkMicNewType(type);
    // setIsFixedLayout((+is_fixed_layout) || 0);
  };
  // 连麦状态变更通知（新连麦）
  var handleLinkMicLinkMicStatusChange = function handleLinkMicLinkMicStatusChange(_ref4) {
    var iLinkMicType = _ref4.iLinkMicType,
      iStatus = _ref4.iStatus;
    Logger.log('handleLinkMicLinkMicStatusChange', {
      iLinkMicType: iLinkMicType,
      iStatus: iStatus
    });
    var type = iStatus === 1 ? iLinkMicType : 0;
    setLinkMicType(type);
  };

  // 获取数据
  useEffect(function () {
    // 初始化
    if (roomInfo !== null && roomInfo !== void 0 && roomInfo.anchorId) {
      setLoading(true);
      var auId = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.anchorId;
      starShowWupModel.getLinkMicList(auId).then(function (data) {
        var iLinkMicType = data.iLinkMicType,
          tAudienceInfo = data.tAudienceInfo,
          tInfo = data.tInfo;
        var isNewType = iLinkMicType === kLINKMIC_AUDIENCE_NEW;
        handleLinkMicLinkMicClientDataChange({
          iLinkMicType: iLinkMicType,
          mData: tAudienceInfo === null || tAudienceInfo === void 0 ? void 0 : tAudienceInfo.mData
        });
        if (data.mpClientData.link_mode === 'team_link_pk_two' && iLinkMicType === kLINKMIC_TEAM) {
          setLinkMicList(Object.values(data.tPosInfo.mUserInfo).filter(function (user) {
            return String(user.lUID) !== String(auId);
          }));
        } else if (isNewType) {
          handleLinkMicUserInfoChange({
            mUserInfo: tAudienceInfo === null || tAudienceInfo === void 0 ? void 0 : tAudienceInfo.mUserInfo
          });
        } else {
          handleLinkMicListInfo({
            vUsers: tInfo === null || tInfo === void 0 ? void 0 : tInfo.vUsers
          });
        }
      }).finally(function () {
        setLoading(false);
      });
    }
    // 监听广播
    starShowSocketModel.on('LinkMicListInfo', handleLinkMicListInfo); // 普通连麦
    starShowSocketModel.on(EUriLinkMicUserInfoChange, handleLinkMicUserInfoChange); // 新连麦（聊天室、派对）
    starShowSocketModel.on(EUriLinkMicClientDataChange, handleLinkMicLinkMicClientDataChange); // mpClientData 变更通知
    starShowSocketModel.on(EUriLinkMicStatusChange, handleLinkMicLinkMicStatusChange); // 连麦状态变更通知
    return function () {
      starShowSocketModel.off('LinkMicListInfo', handleLinkMicListInfo);
      starShowSocketModel.off(EUriLinkMicUserInfoChange, handleLinkMicUserInfoChange);
      starShowSocketModel.off(EUriLinkMicClientDataChange, handleLinkMicLinkMicClientDataChange);
      starShowSocketModel.off(EUriLinkMicStatusChange, handleLinkMicLinkMicStatusChange); // 连麦状态变更通知
    };
  }, [roomInfo]);
  return {
    linkMicList: linkMicList,
    linkMicType: linkMicType,
    linkMicNewType: linkMicNewType,
    loading: loading
  };
};

// redux通知多人送礼组件
export var useLinkMicSeats = function useLinkMicSeats(roomInfo, linkMicNewList, linkMicList, linkMicType) {
  var dispatch = useDispatch();
  var isNewType = linkMicType === kLINKMIC_AUDIENCE_NEW;
  // 连麦座位
  useEffect(function () {
    if (isNewType) {
      var seats = linkMicNewList.slice(1).filter(function (item) {
        return !(item !== null && item !== void 0 && item.isEmpty);
      }) // 新连麦模式，首位0是主播
      .map(function (item) {
        var iIndex = item.iIndex,
          uid = item.uid;
        var listItem = linkMicList === null || linkMicList === void 0 ? void 0 : linkMicList[iIndex];
        return {
          iIndex: iIndex,
          lUID: uid,
          sNickName: listItem === null || listItem === void 0 ? void 0 : listItem.sNickName,
          tUserInfo: {
            sAvatar: listItem === null || listItem === void 0 ? void 0 : listItem.sAvatar
          }
        };
      });
      dispatch(updateLinkMicSeats(seats));
    } else {
      dispatch(updateLinkMicSeats([]));
    }
  }, [linkMicNewList, dispatch, isNewType, linkMicList]);
  // 主播
  useEffect(function () {
    if (isNewType && roomInfo !== null && roomInfo !== void 0 && roomInfo.anchorId) {
      var anchorId = roomInfo.anchorId,
        avatarUrl = roomInfo.avatarUrl,
        nickname = roomInfo.nickname;
      var host = {
        iIndex: 0,
        lUID: anchorId,
        sNickName: nickname,
        tUserInfo: {
          sAvatar: avatarUrl
        }
      };
      dispatch(updateLinkMicHost(host));
    } else {
      dispatch(updateLinkMicHost(null));
    }
  }, [roomInfo, dispatch, isNewType]);
  // 多人送礼显示接受者
  useEffect(function () {
    dispatch(updateShowRecipient(isNewType));
  }, [isNewType, dispatch]);
};

// 旧连麦需要
export function useFullscreen(player) {
  var _useState9 = useState(false),
    _useState10 = _slicedToArray(_useState9, 2),
    fullscreen = _useState10[0],
    setFullscreen = _useState10[1];
  var onFullscreenChange = useCallback(function (data) {
    setFullscreen(data.value);
  }, [setFullscreen]);
  useEffect(function () {
    player.onStateChange('isFullscreen', onFullscreenChange);
    return function () {
      player.offStateChange('isFullscreen', onFullscreenChange);
    };
  }, [player, onFullscreenChange]);
  return [fullscreen, setFullscreen];
}

// 魅力值
export var useCharmMap = function useCharmMap(anchorId, linkMicNewType, langPkg) {
  var _useState11 = useState(null),
    _useState12 = _slicedToArray(_useState11, 2),
    charmMap = _useState12[0],
    setCharmMap = _useState12[1];
  var _useState13 = useState([]),
    _useState14 = _slicedToArray(_useState13, 2),
    maxCharmId = _useState14[0],
    setMaxCharmId = _useState14[1];
  var _useState15 = useState(-1),
    _useState16 = _slicedToArray(_useState15, 2),
    operation = _useState16[0],
    setOperation = _useState16[1];

  // 回调
  var handleCharmValue = useCallback(function (res) {
    var mGuestCharmValue = res.mGuestCharmValue,
      lAnchorUid = res.lAnchorUid,
      iOperations = res.iOperations;
    if (String(lAnchorUid) === String(anchorId)) {
      setOperation(iOperations);
      if (iOperations) {
        var _Object$keys;
        // 开启
        setCharmMap(mGuestCharmValue);
        // 第一名
        var keys = linkMicNewType === 3 ? (_Object$keys = Object.keys(mGuestCharmValue)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.filter(function (key) {
          return String(key) !== String(anchorId);
        }) : Object.keys(mGuestCharmValue); // 普通连麦不展示主播魅力值
        var max = 0;
        var maxId = [];
        keys.forEach(function (key) {
          if (mGuestCharmValue[key] > 0) {
            if (mGuestCharmValue[key] > max) {
              max = mGuestCharmValue[key];
              maxId = [key];
            } else if (mGuestCharmValue[key] === max) {
              maxId.push(key);
            }
          }
        });
        setMaxCharmId(maxId);
      } else {
        // 关闭
        setCharmMap(null);
      }
    }
  }, [anchorId, linkMicNewType]);

  // 初始化
  useEffect(function () {
    if (anchorId && linkMicNewType) {
      meetingWupModel.getCharmValue(anchorId).then(handleCharmValue);
    }
  }, [anchorId, linkMicNewType, handleCharmValue]);

  // 监听广播
  useEffect(function () {
    if (anchorId) {
      meetingSocketModel.on('CharmValue', handleCharmValue);
      return function () {
        meetingSocketModel.off('CharmValue', handleCharmValue);
      };
    }
    return null;
  }, [anchorId, handleCharmValue]);

  // 开启/关闭/重置提示
  var getMsg = useCallback(function (key) {
    var map = {
      1: langPkg === null || langPkg === void 0 ? void 0 : langPkg.charmMsgOn,
      0: langPkg === null || langPkg === void 0 ? void 0 : langPkg.charmMsgOff,
      2: langPkg === null || langPkg === void 0 ? void 0 : langPkg.charmMsgReset
    };
    return map[key];
  }, [langPkg]);
  useEffect(function () {
    if (linkMicNewType && getMsg(operation)) {
      Message.info(getMsg(operation));
    }
  }, [operation, getMsg, linkMicNewType]);
  return {
    charmMap: charmMap,
    maxCharmId: maxCharmId
  };
};
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './MatchAnchorInfo';
import './LiveRoomView';
var MatchRecommendView = function MatchRecommendView() {
  this.matchStatus = 0;
  this.matchAnchorInfo = new NimoBuss.MatchAnchorInfo();
  this.liveRoomView = new NimoBuss.LiveRoomView();
};
MatchRecommendView.prototype._clone = function () {
  return new NimoBuss.MatchRecommendView();
};
MatchRecommendView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchRecommendView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchRecommendView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.matchStatus);
  os.writeStruct(1, this.matchAnchorInfo);
  os.writeStruct(2, this.liveRoomView);
};
MatchRecommendView.prototype.readFrom = function (is) {
  this.matchStatus = is.readInt32(0, false, this.matchStatus);
  this.matchAnchorInfo = is.readStruct(1, false, this.matchAnchorInfo);
  this.liveRoomView = is.readStruct(2, false, this.liveRoomView);
};
NimoBuss.MatchRecommendView = MatchRecommendView;
export default MatchRecommendView;
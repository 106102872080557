import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var SimpleUserRankInfo = function SimpleUserRankInfo() {
  this.userId = 0;
  this.udbUserId = 0;
  this.nickName = "";
  this.avatarUrl = "";
  this.score = 0;
  this.rank = 0;
  this.countryCode = "";
};
SimpleUserRankInfo.prototype._clone = function () {
  return new Nimo.SimpleUserRankInfo();
};
SimpleUserRankInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SimpleUserRankInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SimpleUserRankInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.userId);
  os.writeInt64(1, this.udbUserId);
  os.writeString(2, this.nickName);
  os.writeString(3, this.avatarUrl);
  os.writeDouble(4, this.score);
  os.writeInt64(5, this.rank);
  os.writeString(6, this.countryCode);
};
SimpleUserRankInfo.prototype.readFrom = function (is) {
  this.userId = is.readInt64(0, false, this.userId);
  this.udbUserId = is.readInt64(1, false, this.udbUserId);
  this.nickName = is.readString(2, false, this.nickName);
  this.avatarUrl = is.readString(3, false, this.avatarUrl);
  this.score = is.readDouble(4, false, this.score);
  this.rank = is.readInt64(5, false, this.rank);
  this.countryCode = is.readString(6, false, this.countryCode);
};
Nimo.SimpleUserRankInfo = SimpleUserRankInfo;
export default SimpleUserRankInfo;
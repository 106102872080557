import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var VIPLevelChangeNotice = function VIPLevelChangeNotice() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.lAnchorId = 0;
  this.iVIPLevel = 0;
};
VIPLevelChangeNotice.prototype._clone = function () {
  return new Nimo.VIPLevelChangeNotice();
};
VIPLevelChangeNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VIPLevelChangeNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VIPLevelChangeNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt64(2, this.lAnchorId);
  os.writeInt32(3, this.iVIPLevel);
};
VIPLevelChangeNotice.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.lAnchorId = is.readInt64(2, false, this.lAnchorId);
  this.iVIPLevel = is.readInt32(3, false, this.iVIPLevel);
};
Nimo.VIPLevelChangeNotice = VIPLevelChangeNotice;
export default VIPLevelChangeNotice;
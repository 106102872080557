import TafMessageSocketModel from '../TafMessageSocketModel';
import MeetingInfo from '@client/jce/Nimo/MeetingInfo';
import CharmValueRsq from '@client/jce/Nimo/CharmValueRsq';
import VoiceRoomEmojiNotice from '@client/jce/Nimo/VoiceRoomEmojiNotice';
import MeetingStatus from '@client/jce/Nimo/MeetingStatus';
import RoomManagerNotice from '@client/jce/Nimo/RoomManagerNotice';
import EURI from '@client/jce/Nimo/EURI';
var EUriMeetingSeatChange = EURI.EUriMeetingSeatChange,
  EUriCharmValue = EURI.EUriCharmValue,
  EUriVoiceRoomEmojiNotice = EURI.EUriVoiceRoomEmojiNotice,
  EUriMeetingStatusChange = EURI.EUriMeetingStatusChange,
  EUriUserSetRoomManager = EURI.EUriUserSetRoomManager;
export { MeetingInfo, EUriMeetingSeatChange };
export default new TafMessageSocketModel({
  lazyInitSocket: true,
  broadcasts: {
    MeetingSeatChangeNotice: [EUriMeetingSeatChange, MeetingInfo],
    CharmValue: [EUriCharmValue, CharmValueRsq],
    Emoji: [EUriVoiceRoomEmojiNotice, VoiceRoomEmojiNotice],
    //  （房间广播）语音房房间会话状态变化 struct MeetingStatus
    StatusChange: [EUriMeetingStatusChange, MeetingStatus],
    UserSetRoomManager: [EUriUserSetRoomManager, RoomManagerNotice]
  }
});
/**
 * 将HTML转义为实体
 *
 * @export
 * @param {*} html
 * @returns
 */
export function escape(html) {
  var elem = document.createElement('div');
  var txt = document.createTextNode(html);
  elem.appendChild(txt);
  return elem.innerHTML;
}

/**
 * 将实体转回为HTML
 *
 * @export
 * @param {*} str
 * @returns
 */
export function unescape(str) {
  var elem = document.createElement('div');
  elem.innerHTML = str;
  return elem.innerText || elem.textContent;
}

// https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript
export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}
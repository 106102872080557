import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './LinkMicUserInfo';
var LinkMicUserInfoChange = function LinkMicUserInfoChange() {
  this.lRoomID = 0;
  this.iLinkMicType = 0;
  this.mUserInfo = new Taf.Map(new Taf.INT32(), new Nimo.LinkMicUserInfo());
};
LinkMicUserInfoChange.prototype._clone = function () {
  return new Nimo.LinkMicUserInfoChange();
};
LinkMicUserInfoChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicUserInfoChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicUserInfoChange.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomID);
  os.writeInt32(1, this.iLinkMicType);
  os.writeMap(2, this.mUserInfo);
};
LinkMicUserInfoChange.prototype.readFrom = function (is) {
  this.lRoomID = is.readInt64(0, false, this.lRoomID);
  this.iLinkMicType = is.readInt32(1, false, this.iLinkMicType);
  this.mUserInfo = is.readMap(2, false, this.mUserInfo);
};
Nimo.LinkMicUserInfoChange = LinkMicUserInfoChange;
export default LinkMicUserInfoChange;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PKRankInfo';
import './UserLinkMicBoxInfo';
var LinkMicListUser = function LinkMicListUser() {
  this.lUID = 0;
  this.lRoomID = 0;
  this.sAvatar = "";
  this.sNickName = "";
  this.sAlise = "";
  this.iGender = 0;
  this.iTemplateType = 0;
  this.tPKRankInfo = new Nimo.PKRankInfo();
  this.tBoxInfo = new Nimo.UserLinkMicBoxInfo();
};
LinkMicListUser.prototype._clone = function () {
  return new Nimo.LinkMicListUser();
};
LinkMicListUser.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicListUser.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicListUser.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeInt64(1, this.lRoomID);
  os.writeString(2, this.sAvatar);
  os.writeString(3, this.sNickName);
  os.writeString(4, this.sAlise);
  os.writeInt32(5, this.iGender);
  os.writeInt32(6, this.iTemplateType);
  os.writeStruct(7, this.tPKRankInfo);
  os.writeStruct(8, this.tBoxInfo);
};
LinkMicListUser.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.lRoomID = is.readInt64(1, false, this.lRoomID);
  this.sAvatar = is.readString(2, false, this.sAvatar);
  this.sNickName = is.readString(3, false, this.sNickName);
  this.sAlise = is.readString(4, false, this.sAlise);
  this.iGender = is.readInt32(5, false, this.iGender);
  this.iTemplateType = is.readInt32(6, false, this.iTemplateType);
  this.tPKRankInfo = is.readStruct(7, false, this.tPKRankInfo);
  this.tBoxInfo = is.readStruct(8, false, this.tBoxInfo);
};
Nimo.LinkMicListUser = LinkMicListUser;
export default LinkMicListUser;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var FansLevelUpNotice = function FansLevelUpNotice() {
  this.lRoomId = 0;
  this.sUserNick = "";
  this.iFansLevel = 0;
};
FansLevelUpNotice.prototype._clone = function () {
  return new Nimo.FansLevelUpNotice();
};
FansLevelUpNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FansLevelUpNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FansLevelUpNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeString(1, this.sUserNick);
  os.writeInt32(2, this.iFansLevel);
};
FansLevelUpNotice.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.sUserNick = is.readString(1, false, this.sUserNick);
  this.iFansLevel = is.readInt32(2, false, this.iFansLevel);
};
Nimo.FansLevelUpNotice = FansLevelUpNotice;
export default FansLevelUpNotice;
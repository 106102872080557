import { loadLangPkgWithAutoLang } from '@client/common/lang';
import auth from '@client/common/services/auth';
import Message from '@auto/Message';

// 关注接口错误提示
export var handleFollowErrorMessage = function handleFollowErrorMessage(err) {
  loadLangPkgWithAutoLang(require('@client/common/lang/base')).then(function (lang) {
    var msg = '';
    switch (err === null || err === void 0 ? void 0 : err.code) {
      case 10305:
      case 10304:
      case 501:
        msg = lang.logExpire;
        auth.autoLogout();
        break;
      case 16004:
        // 已关注主播默认不提示
        break;
      case 16020:
        msg = lang.followBeyondLimit;
        break;
      case 15070:
        msg = lang.blockedWithFollow;
        break;
      case 16003:
        // 调用太频繁
        msg = lang.tooFrequent;
        break;
      default:
        msg = lang.followFail;
        break;
    }
    if (msg) {
      Message.error(msg);
    }
  });
};
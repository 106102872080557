import has from 'lodash/has';
import rock from './web_rock.svga';
import scissors from './web_scissors.svga';
import cloth from './web_cloth.svga';
var map = {
  1: rock,
  2: scissors,
  3: cloth
};

/**
 * return svga url by value
 * @param {number} value
 * @returns {string}
 */
export var getFingerGuessingSvga = function getFingerGuessingSvga(value) {
  if (has(map, value)) {
    return map[value];
  }
  return '';
};
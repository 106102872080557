// import React from 'react';
// import ReactDOM from 'react-dom';
import ReactVideoDisabledPanel from './main';
import './style.scss';
export default ReactVideoDisabledPanel;

// export class VideoDisabledPanel {
//   constructor(player, config, /* options */) {
//     const { poster, ...restConfig } = config;
//     this.parent = player;
//     this.config = restConfig;
//     this.poster = poster;
//     // this.options = options;
//     this.initElement();
//     this.initView();
//   }

//   initElement() {
//     const { containerId } = this.parent;
//     const container = document.getElementById(containerId);
//     const div = document.createElement('div');
//     div.id = 'nimo-player_video-disabled-panel';
//     this.id = div.id;
//     container.appendChild(div);
//   }

//   initView() {
//     ReactDOM.render(
//       <ReactVideoDisabledPanel 
//         parent={this.parent}
//         config={this.config}
//         poster={this.poster}
//       />,
//       document.getElementById(this.id)
//     );
//   }
// }
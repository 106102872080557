import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EMsgDataType = {
  EMSGDATATYPE_COMM: 0,
  EMSGDATATYPE_PICTURE: 1,
  EMSGDATATYPE_RICHTEXT: 2,
  EMSGDATATYPE_GROUPNOTICE: 3,
  EMSGDATATYPE_NOTICE: 4,
  EMSGDATATYPE_SHARE: 5,
  EMSGDATATYPE_SHARE_LIVE: 6,
  EMSGDATATYPE_SHARE_VIDEO: 7,
  EMSGDATATYPE_SHARE_POST: 8,
  EMSGDATATYPE_CARD: 9
};
Nimo.EMsgDataType = EMsgDataType;
export default EMsgDataType;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var RoomWelcomeNotice = function RoomWelcomeNotice() {
  this.sWelcomeText = "";
  this.sIcon = "";
  this.sTextColor = "";
  this.sBGColor = "";
  this.lRoomId = 0;
};
RoomWelcomeNotice.prototype._clone = function () {
  return new Nimo.RoomWelcomeNotice();
};
RoomWelcomeNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomWelcomeNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomWelcomeNotice.prototype.writeTo = function (os) {
  os.writeString(0, this.sWelcomeText);
  os.writeString(1, this.sIcon);
  os.writeString(2, this.sTextColor);
  os.writeString(3, this.sBGColor);
  os.writeInt64(4, this.lRoomId);
};
RoomWelcomeNotice.prototype.readFrom = function (is) {
  this.sWelcomeText = is.readString(0, false, this.sWelcomeText);
  this.sIcon = is.readString(1, false, this.sIcon);
  this.sTextColor = is.readString(2, false, this.sTextColor);
  this.sBGColor = is.readString(3, false, this.sBGColor);
  this.lRoomId = is.readInt64(4, false, this.lRoomId);
};
Nimo.RoomWelcomeNotice = RoomWelcomeNotice;
export default RoomWelcomeNotice;
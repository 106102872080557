import { ActBadgeLottery } from '@client/common/components/ActBadge/constants.js';
import { session } from '@client/common/utils/storage.js';
import { getRoomId } from "@client/common/utils/fn.js";
export var markNodeLotteryEntryClick = function markNodeLotteryEntryClick(badges, data, from) {
  if (!data) return;
  var roomId = getRoomId(data);
  if (!roomId) return;
  var isLotteryEntryShow = badges && Array.isArray(badges) && badges.find(function (x) {
    return x && x.type === ActBadgeLottery;
  });
  var key = "er_nlottery_click_".concat(roomId);
  if (isLotteryEntryShow) {
    session.set(key, JSON.stringify({
      _from: from
    }));
  } else {
    session.remove(key);
  }
};
import has from 'lodash/has';
import dice1 from './web_dice_1.svga';
import dice2 from './web_dice_2.svga';
import dice3 from './web_dice_3.svga';
import dice4 from './web_dice_4.svga';
import dice5 from './web_dice_5.svga';
import dice6 from './web_dice_6.svga';
var map = {
  1: dice1,
  2: dice2,
  3: dice3,
  4: dice4,
  5: dice5,
  6: dice6
};

/**
 * return svga url by value
 * @param {number} value
 * @returns {string}
 */
export var getDiceSvga = function getDiceSvga(value) {
  if (has(map, value)) {
    return map[value];
  }
  return '';
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var InvitaUpMcRsp = function InvitaUpMcRsp() {
  this.iRet = 0;
  this.sErrMsg = "";
  this.lUId = 0;
  this.sStreamKey = 0;
  this.iBlacklistInterceptType = 0;
  this.iBlacklistInterceptFinishTime = 0;
};
InvitaUpMcRsp.prototype._clone = function () {
  return new Nimo.InvitaUpMcRsp();
};
InvitaUpMcRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
InvitaUpMcRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
InvitaUpMcRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iRet);
  os.writeString(1, this.sErrMsg);
  os.writeInt64(2, this.lUId);
  os.writeUInt32(3, this.sStreamKey);
  os.writeInt32(4, this.iBlacklistInterceptType);
  os.writeInt32(5, this.iBlacklistInterceptFinishTime);
};
InvitaUpMcRsp.prototype.readFrom = function (is) {
  this.iRet = is.readInt32(0, false, this.iRet);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.lUId = is.readInt64(2, false, this.lUId);
  this.sStreamKey = is.readUInt32(3, false, this.sStreamKey);
  this.iBlacklistInterceptType = is.readInt32(4, false, this.iBlacklistInterceptType);
  this.iBlacklistInterceptFinishTime = is.readInt32(5, false, this.iBlacklistInterceptFinishTime);
};
Nimo.InvitaUpMcRsp = InvitaUpMcRsp;
export default InvitaUpMcRsp;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './VoiceRoomEmojiConfig';
var GetVoiceRoomEmojiListRsp = function GetVoiceRoomEmojiListRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vVoiceRoomEmoji = new Taf.Vector(new Nimo.VoiceRoomEmojiConfig());
};
GetVoiceRoomEmojiListRsp.prototype._clone = function () {
  return new Nimo.GetVoiceRoomEmojiListRsp();
};
GetVoiceRoomEmojiListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetVoiceRoomEmojiListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetVoiceRoomEmojiListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeVector(2, this.vVoiceRoomEmoji);
};
GetVoiceRoomEmojiListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vVoiceRoomEmoji = is.readVector(2, false, this.vVoiceRoomEmoji);
};
Nimo.GetVoiceRoomEmojiListRsp = GetVoiceRoomEmojiListRsp;
export default GetVoiceRoomEmojiListRsp;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PresenterInfo';
var NoticePresenterLinkChange = function NoticePresenterLinkChange() {
  this.iType = 0;
  this.tInfoA = new Nimo.PresenterInfo();
  this.tInfoB = new Nimo.PresenterInfo();
  this.iReason = 0;
  this.lActiveId = 0;
  this.lInfoKey = 0;
};
NoticePresenterLinkChange.prototype._clone = function () {
  return new Nimo.NoticePresenterLinkChange();
};
NoticePresenterLinkChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NoticePresenterLinkChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NoticePresenterLinkChange.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeStruct(1, this.tInfoA);
  os.writeStruct(2, this.tInfoB);
  os.writeInt32(3, this.iReason);
  os.writeInt64(4, this.lActiveId);
  os.writeInt64(5, this.lInfoKey);
};
NoticePresenterLinkChange.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.tInfoA = is.readStruct(1, false, this.tInfoA);
  this.tInfoB = is.readStruct(2, false, this.tInfoB);
  this.iReason = is.readInt32(3, false, this.iReason);
  this.lActiveId = is.readInt64(4, false, this.lActiveId);
  this.lInfoKey = is.readInt64(5, false, this.lInfoKey);
};
Nimo.NoticePresenterLinkChange = NoticePresenterLinkChange;
export default NoticePresenterLinkChange;
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var DeliverAnchorAnnouncementByRoomMsg = function DeliverAnchorAnnouncementByRoomMsg() {
  this.channelId = 0;
  this.anchorAnnouncement = "";
};
DeliverAnchorAnnouncementByRoomMsg.prototype._clone = function () {
  return new NimoBuss.DeliverAnchorAnnouncementByRoomMsg();
};
DeliverAnchorAnnouncementByRoomMsg.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DeliverAnchorAnnouncementByRoomMsg.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DeliverAnchorAnnouncementByRoomMsg.prototype.writeTo = function (os) {
  os.writeInt64(0, this.channelId);
  os.writeString(1, this.anchorAnnouncement);
};
DeliverAnchorAnnouncementByRoomMsg.prototype.readFrom = function (is) {
  this.channelId = is.readInt64(0, false, this.channelId);
  this.anchorAnnouncement = is.readString(1, false, this.anchorAnnouncement);
};
NimoBuss.DeliverAnchorAnnouncementByRoomMsg = DeliverAnchorAnnouncementByRoomMsg;
export default DeliverAnchorAnnouncementByRoomMsg;
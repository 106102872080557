import nimoReport from '@client/common/services/report';

/**
 * 直播间在违规提示中点击关闭按钮时
 * @param {string} anchorId 主播uid
 * @param {string} gameId 品类id
 */
export var reportClose = function reportClose(anchorId, gameId) {
  var reportObj = nimoReport._formatReportObj('click/live_violation_tips_close', '点击直播间违规提示关闭按钮', {
    udbid_: anchorId,
    category_id_: gameId
  });
  nimoReport.report(reportObj);
};

/**
 * 直播间中展示出违规提示时
 * @param {string} anchorId 主播uid
 * @param {string} gameId 品类id
 */
export var reportShow = function reportShow(anchorId, gameId) {
  var reportObj = nimoReport._formatReportObj('show/live_violation_tips', '曝光直播间违规提示', {
    udbid_: anchorId,
    category_id_: gameId
  });
  nimoReport.report(reportObj);
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PresenterInfo';
var PresenterLinkInfo = function PresenterLinkInfo() {
  this.tInfoA = new Nimo.PresenterInfo();
  this.tInfoB = new Nimo.PresenterInfo();
  this.iLinkState = 0;
};
PresenterLinkInfo.prototype._clone = function () {
  return new Nimo.PresenterLinkInfo();
};
PresenterLinkInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PresenterLinkInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PresenterLinkInfo.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tInfoA);
  os.writeStruct(1, this.tInfoB);
  os.writeInt32(2, this.iLinkState);
};
PresenterLinkInfo.prototype.readFrom = function (is) {
  this.tInfoA = is.readStruct(0, false, this.tInfoA);
  this.tInfoB = is.readStruct(1, false, this.tInfoB);
  this.iLinkState = is.readInt32(2, false, this.iLinkState);
};
Nimo.PresenterLinkInfo = PresenterLinkInfo;
export default PresenterLinkInfo;
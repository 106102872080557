import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var GetAnchorFollowInfoReq = function GetAnchorFollowInfoReq() {
  this.baseParam = new NimoBuss.BaseParam();
};
GetAnchorFollowInfoReq.prototype._clone = function () {
  return new NimoBuss.GetAnchorFollowInfoReq();
};
GetAnchorFollowInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetAnchorFollowInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetAnchorFollowInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
};
GetAnchorFollowInfoReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
};
NimoBuss.GetAnchorFollowInfoReq = GetAnchorFollowInfoReq;
export default GetAnchorFollowInfoReq;
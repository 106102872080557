import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './McUser';
import './PresenterLinkInfo';
import './ShowMicBox';
var GetRoomMcInfoRsp = function GetRoomMcInfoRsp() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.sStreamKey = 0;
  this.iStatus = 0;
  this.isAutoMc = true;
  this.isVisableMc = true;
  this.iTimeOut1 = 0;
  this.iMaxMembers = 0;
  this.vUserList = new Taf.Vector(new Nimo.McUser());
  this.iType = 0;
  this.sJson = "";
  this.bIsWait = true;
  this.iWaitsNum = 0;
  this.iShowState = 0;
  this.tInfo = new Nimo.PresenterLinkInfo();
  this.vShowMicBox = new Taf.Vector(new Nimo.ShowMicBox());
};
GetRoomMcInfoRsp.prototype._clone = function () {
  return new Nimo.GetRoomMcInfoRsp();
};
GetRoomMcInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomMcInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomMcInfoRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeUInt32(2, this.sStreamKey);
  os.writeInt32(3, this.iStatus);
  os.writeBoolean(4, this.isAutoMc);
  os.writeBoolean(5, this.isVisableMc);
  os.writeInt32(6, this.iTimeOut1);
  os.writeInt32(7, this.iMaxMembers);
  os.writeVector(8, this.vUserList);
  os.writeInt32(9, this.iType);
  os.writeString(10, this.sJson);
  os.writeBoolean(11, this.bIsWait);
  os.writeInt32(12, this.iWaitsNum);
  os.writeInt32(13, this.iShowState);
  os.writeStruct(14, this.tInfo);
  os.writeVector(15, this.vShowMicBox);
};
GetRoomMcInfoRsp.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.sStreamKey = is.readUInt32(2, false, this.sStreamKey);
  this.iStatus = is.readInt32(3, false, this.iStatus);
  this.isAutoMc = is.readBoolean(4, false, this.isAutoMc);
  this.isVisableMc = is.readBoolean(5, false, this.isVisableMc);
  this.iTimeOut1 = is.readInt32(6, false, this.iTimeOut1);
  this.iMaxMembers = is.readInt32(7, false, this.iMaxMembers);
  this.vUserList = is.readVector(8, false, this.vUserList);
  this.iType = is.readInt32(9, false, this.iType);
  this.sJson = is.readString(10, false, this.sJson);
  this.bIsWait = is.readBoolean(11, false, this.bIsWait);
  this.iWaitsNum = is.readInt32(12, false, this.iWaitsNum);
  this.iShowState = is.readInt32(13, false, this.iShowState);
  this.tInfo = is.readStruct(14, false, this.tInfo);
  this.vShowMicBox = is.readVector(15, false, this.vShowMicBox);
};
Nimo.GetRoomMcInfoRsp = GetRoomMcInfoRsp;
export default GetRoomMcInfoRsp;
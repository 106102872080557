/**
 * 原本是一种dotType对应一列视频，
 * 后续变成了多对多的关系，
 * 可能多种dotType对应一列视频（如主播视频），
 * 也可能一种dotType对应多列视频（如热门剪辑和最新剪辑）。
 */
var dotTypeMap = [{}, {
  textKey: 'replay',
  label: 'replay',
  labelText: '直播回放',
  reportTitle: "{\"eid\": \"personalpage_replay_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u76F4\u64AD\u56DE\u653E_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_replay_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u76F4\u64AD\u56DE\u653E_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_replay_click',
    eid_desc: '主播个人页_直播回放_点击'
  },
  dotTypeGroup: [1],
  seoLangKey: 'replay'
}, {}, {
  textKey: 'videos',
  label: 'anchorvideo',
  labelText: '主播视频',
  reportTitle: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_anchorvideo_click',
    eid_desc: '主播个人页_主播视频_点击'
  },
  dotTypeGroup: [3, 6, 7],
  // 在个人页的‘主播视频’分类要展示下列几种dotType，而不是只展示dotType=3
  seoLangKey: 'anchorvideo'
}, {
  textKey: 'edit',
  label: 'clip',
  labelText: '剪辑',
  reportTitle: "{\"eid\": \"personalpage_clip_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u526A\u8F91_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_clip_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u526A\u8F91_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_clip_click',
    eid_desc: '主播个人页_剪辑_点击'
  },
  dotTypeGroup: [4],
  seoLangKey: 'clip'
}, {}, {
  textKey: 'videos',
  label: 'anchorvideo',
  labelText: '主播视频',
  reportTitle: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_anchorvideo_click',
    eid_desc: '主播个人页_主播视频_点击'
  },
  dotTypeGroup: [3, 6, 7],
  seoLangKey: 'anchorvideo'
}, {
  textKey: 'videos',
  label: 'anchorvideo',
  labelText: '主播视频',
  reportTitle: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_anchorvideo_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u4E3B\u64AD\u89C6\u9891_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_anchorvideo_click',
    eid_desc: '主播个人页_主播视频_点击'
  },
  dotTypeGroup: [3, 6, 7],
  seoLangKey: 'anchorvideo'
}];
var popularClipInfo = {
  textKey: 'popularClip',
  label: 'popular-clip',
  orderColumn: 'playNum',
  labelText: '热门剪辑',
  reportTitle: "{\"eid\": \"personalpage_popular-clip_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u70ED\u95E8\u526A\u8F91_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"title\"}}",
  reportMore: "{\"eid\": \"personalpage_popular-clip_more_click\", \"eid_desc\": \"\u4E3B\u64AD\u4E2A\u4EBA\u9875_\u70ED\u95E8\u526A\u8F91_\u66F4\u591A_\u70B9\u51FB\", \"prop\": {\"position\": \"more\"}}",
  reportItemClick: {
    eid: 'personalpage_popular-clip_click',
    eid_desc: '主播个人页_热门剪辑_点击'
  },
  dotTypeGroup: [4],
  seoLangKey: 'clip'
};
export var getDotTypeFilteredList = function getDotTypeFilteredList() {
  return [dotTypeMap[1], dotTypeMap[3], popularClipInfo, dotTypeMap[4]];
};
export var getSeoLangKey = function getSeoLangKey(label) {
  var _getDotTypeFilteredLi;
  return ((_getDotTypeFilteredLi = getDotTypeFilteredList().find(function (item) {
    return item.label === label;
  })) === null || _getDotTypeFilteredLi === void 0 ? void 0 : _getDotTypeFilteredLi.seoLangKey) || '';
};
export var getDotTypeInfoByLabel = function getDotTypeInfoByLabel(label) {
  return getDotTypeFilteredList().find(function (item) {
    return item.label === label;
  });
};
export var getDotTypeInfo = function getDotTypeInfo(item) {
  var _ref = item || {},
    dotType = _ref.dotType,
    orderColumn = _ref.orderColumn;
  if (dotType === 4 && orderColumn === 'playNum') {
    return popularClipInfo;
  } else {
    return dotTypeMap[dotType] || {};
  }
};
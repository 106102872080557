import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var VoiceRoomEmojiConfig = function VoiceRoomEmojiConfig() {
  this.sEmojiCode = "";
  this.sAppUrl = "";
  this.sAppDynamicUrl = "";
  this.sWebDynamicUrl = "";
};
VoiceRoomEmojiConfig.prototype._clone = function () {
  return new Nimo.VoiceRoomEmojiConfig();
};
VoiceRoomEmojiConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VoiceRoomEmojiConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VoiceRoomEmojiConfig.prototype.writeTo = function (os) {
  os.writeString(0, this.sEmojiCode);
  os.writeString(1, this.sAppUrl);
  os.writeString(2, this.sAppDynamicUrl);
  os.writeString(3, this.sWebDynamicUrl);
};
VoiceRoomEmojiConfig.prototype.readFrom = function (is) {
  this.sEmojiCode = is.readString(0, false, this.sEmojiCode);
  this.sAppUrl = is.readString(1, false, this.sAppUrl);
  this.sAppDynamicUrl = is.readString(2, false, this.sAppDynamicUrl);
  this.sWebDynamicUrl = is.readString(3, false, this.sWebDynamicUrl);
};
Nimo.VoiceRoomEmojiConfig = VoiceRoomEmojiConfig;
export default VoiceRoomEmojiConfig;
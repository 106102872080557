import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftLuckyBagStatusUpdateNotice = function GiftLuckyBagStatusUpdateNotice() {
  this.lRoomId = 0;
  this.sBagId = "";
  this.iNoticeType = 0;
  this.iCountDown = 0;
};
GiftLuckyBagStatusUpdateNotice.prototype._clone = function () {
  return new Nimo.GiftLuckyBagStatusUpdateNotice();
};
GiftLuckyBagStatusUpdateNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftLuckyBagStatusUpdateNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftLuckyBagStatusUpdateNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeString(1, this.sBagId);
  os.writeInt32(2, this.iNoticeType);
  os.writeInt32(3, this.iCountDown);
};
GiftLuckyBagStatusUpdateNotice.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.sBagId = is.readString(1, false, this.sBagId);
  this.iNoticeType = is.readInt32(2, false, this.iNoticeType);
  this.iCountDown = is.readInt32(3, false, this.iCountDown);
};
Nimo.GiftLuckyBagStatusUpdateNotice = GiftLuckyBagStatusUpdateNotice;
export default GiftLuckyBagStatusUpdateNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UidNickName';
var MsgItem = function MsgItem() {
  this.lMsgId = 0;
  this.lSndrUid = 0;
  this.lRcvrUid = 0;
  this.iDataType = 0;
  this.vData = new Taf.BinBuffer();
  this.lTime = 0;
  this.lSrcMsgId = 0;
  this.bRead = true;
  this.sSenderNick = "";
  this.sSenderAvatar = "";
  this.lClientMsgId = 0;
  this.vAtUsers = new Taf.Vector(new Nimo.UidNickName());
  this.iSenderNimoOfficial = 0;
  this.iNotifcationType = 1;
  this.iNoticeMsgType = 0;
};
MsgItem.prototype._clone = function () {
  return new Nimo.MsgItem();
};
MsgItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgItem.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lMsgId);
  os.writeInt64(1, this.lSndrUid);
  os.writeInt64(2, this.lRcvrUid);
  os.writeInt32(3, this.iDataType);
  os.writeBytes(4, this.vData);
  os.writeInt64(5, this.lTime);
  os.writeInt64(6, this.lSrcMsgId);
  os.writeBoolean(7, this.bRead);
  os.writeString(8, this.sSenderNick);
  os.writeString(9, this.sSenderAvatar);
  os.writeInt64(10, this.lClientMsgId);
  os.writeVector(11, this.vAtUsers);
  os.writeInt32(12, this.iSenderNimoOfficial);
  os.writeInt32(13, this.iNotifcationType);
  os.writeInt32(14, this.iNoticeMsgType);
};
MsgItem.prototype.readFrom = function (is) {
  this.lMsgId = is.readInt64(0, false, this.lMsgId);
  this.lSndrUid = is.readInt64(1, false, this.lSndrUid);
  this.lRcvrUid = is.readInt64(2, false, this.lRcvrUid);
  this.iDataType = is.readInt32(3, false, this.iDataType);
  this.vData = is.readBytes(4, false, this.vData);
  this.lTime = is.readInt64(5, false, this.lTime);
  this.lSrcMsgId = is.readInt64(6, false, this.lSrcMsgId);
  this.bRead = is.readBoolean(7, false, this.bRead);
  this.sSenderNick = is.readString(8, false, this.sSenderNick);
  this.sSenderAvatar = is.readString(9, false, this.sSenderAvatar);
  this.lClientMsgId = is.readInt64(10, false, this.lClientMsgId);
  this.vAtUsers = is.readVector(11, false, this.vAtUsers);
  this.iSenderNimoOfficial = is.readInt32(12, false, this.iSenderNimoOfficial);
  this.iNotifcationType = is.readInt32(13, false, this.iNotifcationType);
  this.iNoticeMsgType = is.readInt32(14, false, this.iNoticeMsgType);
};
Nimo.MsgItem = MsgItem;
export default MsgItem;
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './RegionView';
import './AreaView';
var RegionListView = function RegionListView() {
  this.hotArea = new Taf.Vector(new NimoBuss.RegionView());
  this.areaViews = new Taf.Vector(new NimoBuss.AreaView());
};
RegionListView.prototype._clone = function () {
  return new NimoBuss.RegionListView();
};
RegionListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RegionListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RegionListView.prototype.writeTo = function (os) {
  os.writeVector(0, this.hotArea);
  os.writeVector(1, this.areaViews);
};
RegionListView.prototype.readFrom = function (is) {
  this.hotArea = is.readVector(0, false, this.hotArea);
  this.areaViews = is.readVector(1, false, this.areaViews);
};
NimoBuss.RegionListView = RegionListView;
export default RegionListView;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ThirdMessageNotice = function ThirdMessageNotice() {
  this.iPlatform = 0;
  this.sNickName = "";
  this.lRoomId = 0;
  this.sContent = "";
  this.sBadgeIcon = "";
};
ThirdMessageNotice.prototype._clone = function () {
  return new Nimo.ThirdMessageNotice();
};
ThirdMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ThirdMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ThirdMessageNotice.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iPlatform);
  os.writeString(1, this.sNickName);
  os.writeInt64(2, this.lRoomId);
  os.writeString(3, this.sContent);
  os.writeString(4, this.sBadgeIcon);
};
ThirdMessageNotice.prototype.readFrom = function (is) {
  this.iPlatform = is.readInt32(0, false, this.iPlatform);
  this.sNickName = is.readString(1, false, this.sNickName);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.sContent = is.readString(3, false, this.sContent);
  this.sBadgeIcon = is.readString(4, false, this.sBadgeIcon);
};
Nimo.ThirdMessageNotice = ThirdMessageNotice;
export default ThirdMessageNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './SimpleUserRankInfo';
import './UserLevelObject';
import './FanCard';
var UserRankInfo = function UserRankInfo() {
  this.userId = 0;
  this.udbUserId = 0;
  this.nickName = "";
  this.avatarUrl = "";
  this.score = 0;
  this.rank = 0;
  this.countryCode = "";
  this.liveStatus = 0;
  this.contributors = new Taf.Vector(new Nimo.SimpleUserRankInfo());
  this.rankStatus = 0;
  this.gender = 0;
  this.avatarBoxUrl = "";
  this.dynamicAvatarBoxUrl = "";
  this.userLevelInfo = new Nimo.UserLevelObject();
  this.royalLevel = 0;
  this.fanCard = new Nimo.FanCard();
};
UserRankInfo.prototype._clone = function () {
  return new Nimo.UserRankInfo();
};
UserRankInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserRankInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserRankInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.userId);
  os.writeInt64(1, this.udbUserId);
  os.writeString(2, this.nickName);
  os.writeString(3, this.avatarUrl);
  os.writeDouble(4, this.score);
  os.writeInt64(5, this.rank);
  os.writeString(6, this.countryCode);
  os.writeInt32(7, this.liveStatus);
  os.writeVector(8, this.contributors);
  os.writeInt32(9, this.rankStatus);
  os.writeInt32(10, this.gender);
  os.writeString(11, this.avatarBoxUrl);
  os.writeString(12, this.dynamicAvatarBoxUrl);
  os.writeStruct(13, this.userLevelInfo);
  os.writeInt32(14, this.royalLevel);
  os.writeStruct(15, this.fanCard);
};
UserRankInfo.prototype.readFrom = function (is) {
  this.userId = is.readInt64(0, false, this.userId);
  this.udbUserId = is.readInt64(1, false, this.udbUserId);
  this.nickName = is.readString(2, false, this.nickName);
  this.avatarUrl = is.readString(3, false, this.avatarUrl);
  this.score = is.readDouble(4, false, this.score);
  this.rank = is.readInt64(5, false, this.rank);
  this.countryCode = is.readString(6, false, this.countryCode);
  this.liveStatus = is.readInt32(7, false, this.liveStatus);
  this.contributors = is.readVector(8, false, this.contributors);
  this.rankStatus = is.readInt32(9, false, this.rankStatus);
  this.gender = is.readInt32(10, false, this.gender);
  this.avatarBoxUrl = is.readString(11, false, this.avatarBoxUrl);
  this.dynamicAvatarBoxUrl = is.readString(12, false, this.dynamicAvatarBoxUrl);
  this.userLevelInfo = is.readStruct(13, false, this.userLevelInfo);
  this.royalLevel = is.readInt32(14, false, this.royalLevel);
  this.fanCard = is.readStruct(15, false, this.fanCard);
};
Nimo.UserRankInfo = UserRankInfo;
export default UserRankInfo;
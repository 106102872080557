var map = {};
export function genUnpackMapKey(topic, uri) {
  return "".concat(topic, "_").concat(uri);
}
export function decodeUnpackMapKey(key) {
  return typeof key !== 'undefined' ? "".concat(key).split('_') : [];
}
export default {
  add: function add(key, structure) {
    if (structure) {
      map[key] = structure;
    }
  },
  get: function get(key) {
    if ({}.hasOwnProperty.call(map, key)) {
      return map[key];
    }
    return false;
  },
  remove: function remove(key) {
    if ({}.hasOwnProperty.call(map, key)) {
      map[key] = null;
      delete map[key];
      return true;
    }
    return false;
  }
};
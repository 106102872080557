import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState, useRef, useCallback } from 'react';
import pick from 'lodash/pick';
var addQueue = function addQueue(ref, uid, data) {
  var queue = ref.current[uid];
  if (!queue) {
    queue = [];
    // eslint-disable-next-line no-param-reassign
    ref.current[uid] = queue;
  }
  queue.push(data);
};
var shiftQueue = function shiftQueue(ref, uid) {
  var queue = ref.current[uid];
  return queue && queue.length > 0 ? queue.shift() : undefined;
};
var isFree = function isFree(ref, uid) {
  var queue = ref.current[uid];
  return !(queue && queue.length > 0);
};
export var useAnimationQueues = function useAnimationQueues() {
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    currentAnimation = _useState2[0],
    setCurrentAnimation = _useState2[1];
  var queueRef = useRef({});
  var unlockRef = useRef({});
  var keyRef = useRef(0);
  var setAnimation = function setAnimation(uid, data) {
    setCurrentAnimation(function (map) {
      return map[uid] !== data ? _objectSpread(_objectSpread({}, map), {}, _defineProperty({}, uid, data)) : map;
    });
  };
  var isAnimationUnlock = function isAnimationUnlock(animation) {
    return unlockRef.current[animation.uid] === animation.key;
  };
  var getKey = function getKey() {
    keyRef.current += 1;
    return keyRef.current;
  };
  return {
    current: currentAnimation,
    pick: useCallback(function (uids) {
      queueRef.current = pick(queueRef.current, uids);
      setCurrentAnimation(function (animationMap) {
        var nextMap = _objectSpread({}, animationMap);
        var needUpdate = false;
        Object.keys(nextMap).forEach(function (uid) {
          if (!uids.includes[uid] && nextMap[uid]) {
            delete nextMap[uid];
            needUpdate = true;
          }
        });
        return needUpdate ? nextMap : animationMap;
      });
    }, []),
    unlock: useCallback(function (animation) {
      unlockRef.current[animation.uid] = animation.key;
    }, []),
    isFree: useCallback(function (uid) {
      return isFree(queueRef, uid);
    }, []),
    shift: useCallback(function (uid) {
      var item = shiftQueue(queueRef, uid);
      setAnimation(uid, item);
    }, []),
    add: useCallback(function (uid, data) {
      var animation = {
        key: getKey(),
        uid: uid,
        data: data
      };
      var current = currentAnimation[uid];
      if (current && !isAnimationUnlock(current)) {
        addQueue(queueRef, uid, animation);
      } else {
        setAnimation(uid, animation);
      }
    }, [currentAnimation]),
    set: useCallback(function (uid, data) {
      var animation = {
        key: getKey(),
        uid: uid,
        data: data
      };
      setAnimation(uid, animation);
    }, []),
    unset: useCallback(function (uid) {
      setAnimation(uid, undefined);
    }, [])
  };
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var NoticeUserEvent = function NoticeUserEvent() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.iType = 0;
  this.sMessage = "";
};
NoticeUserEvent.prototype._clone = function () {
  return new Nimo.NoticeUserEvent();
};
NoticeUserEvent.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NoticeUserEvent.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NoticeUserEvent.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt32(2, this.iType);
  os.writeString(3, this.sMessage);
};
NoticeUserEvent.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.iType = is.readInt32(2, false, this.iType);
  this.sMessage = is.readString(3, false, this.sMessage);
};
Nimo.NoticeUserEvent = NoticeUserEvent;
export default NoticeUserEvent;
/* eslint-disable no-unused-expressions */
export var nimoReportLabelPos = function nimoReportLabelPos(report, pos, labelId) {
  report === null || report === void 0 || report.report({
    eid: "usr/click/tag/null",
    eid_desc: "用户/点击/标签/所有界面",
    prop: JSON.stringify({
      'page_': pos,
      // home, all, game, search, clan-page
      'tag_id_': labelId
    })
  });
};
export var nimoReportLabelPosCardBottom = function nimoReportLabelPosCardBottom(report, posNo, labelId) {
  report === null || report === void 0 || report.report({
    eid: "usr/click/tag/channel_bottom",
    eid_desc: "用户/点击/标签/直播间卡片下方",
    prop: JSON.stringify({
      'position_': posNo,
      // 1,2,3...
      'tag_id_': labelId
    })
  });
};
export var nimoReportClickLiveRoomCard = function nimoReportClickLiveRoomCard(report, status, tagType, gameId, from) {
  var map = {
    'home': 0,
    'game': 1,
    'search': 3,
    'all': 4,
    'clan-page': 5
  };
  report === null || report === void 0 || report.report({
    eid: "usr/click/liveroom/all",
    eid_desc: "用户/点击/直播间/全部",
    prop: JSON.stringify({
      'status_': status,
      // 0, 1 有无标签
      'tag_type_': tagType === null || tagType === void 0 ? void 0 : tagType.join(','),
      // 0, 1, 2
      'game_id_': gameId,
      'from_': map[from] // 0, 1, 3, 4, 5
    })
  });
};
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var DiceGameRsp = function DiceGameRsp() {
  this.iCode = 0;
  this.sMessage = "";
  this.iValue = 0;
  this.lUID = 0;
};
DiceGameRsp.prototype._clone = function () {
  return new Nimo.DiceGameRsp();
};
DiceGameRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DiceGameRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DiceGameRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
  os.writeInt32(2, this.iValue);
  os.writeInt64(3, this.lUID);
};
DiceGameRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.iValue = is.readInt32(2, false, this.iValue);
  this.lUID = is.readInt64(3, false, this.lUID);
};
Nimo.DiceGameRsp = DiceGameRsp;
export default DiceGameRsp;
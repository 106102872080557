import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserRankInfo';
var CurrentLiveRankRsp = function CurrentLiveRankRsp() {
  this.ranks = new Taf.Vector(new Nimo.UserRankInfo());
  this.personalInfo = new Nimo.UserRankInfo();
  this.summary = 0;
};
CurrentLiveRankRsp.prototype._clone = function () {
  return new Nimo.CurrentLiveRankRsp();
};
CurrentLiveRankRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CurrentLiveRankRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CurrentLiveRankRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.ranks);
  os.writeStruct(1, this.personalInfo);
  os.writeInt64(2, this.summary);
};
CurrentLiveRankRsp.prototype.readFrom = function (is) {
  this.ranks = is.readVector(0, false, this.ranks);
  this.personalInfo = is.readStruct(1, false, this.personalInfo);
  this.summary = is.readInt64(2, false, this.summary);
};
Nimo.CurrentLiveRankRsp = CurrentLiveRankRsp;
export default CurrentLiveRankRsp;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MsgUserAttribute = function MsgUserAttribute() {
  this.iIsFollowing = 0;
  this.iIsAuthor = 0;
  this.iIsFollowSrc = 0;
};
MsgUserAttribute.prototype._clone = function () {
  return new Nimo.MsgUserAttribute();
};
MsgUserAttribute.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgUserAttribute.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgUserAttribute.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iIsFollowing);
  os.writeInt32(1, this.iIsAuthor);
  os.writeInt32(2, this.iIsFollowSrc);
};
MsgUserAttribute.prototype.readFrom = function (is) {
  this.iIsFollowing = is.readInt32(0, false, this.iIsFollowing);
  this.iIsAuthor = is.readInt32(1, false, this.iIsAuthor);
  this.iIsFollowSrc = is.readInt32(2, false, this.iIsFollowSrc);
};
Nimo.MsgUserAttribute = MsgUserAttribute;
export default MsgUserAttribute;
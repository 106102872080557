// import React from 'react';
// import ReactDOM from 'react-dom';
import ReactPlayBtn from './main';
import './style.scss';
export default ReactPlayBtn;

// export class PlayBtn {
//   constructor(player, config, /* options */) {
//     this.parent = player;
//     this.config = config;
//     // this.options = options;
//     this.initElement();
//     this.initView();
//   }

//   initElement() {
//     const { containerId } = this.parent;
//     const container = document.getElementById(containerId);
//     const div = document.createElement('div');
//     div.id = 'nimo-player_play-btn';
//     this.id = div.id;
//     container.appendChild(div);
//   }

//   initView() {
//     ReactDOM.render(
//       <ReactPlayBtn 
//         parent={this.parent}
//         config={this.config}
//       />,
//       document.getElementById(this.id)
//     );
//   }
// }
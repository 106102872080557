import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var GetAreasReq = function GetAreasReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.language = "";
};
GetAreasReq.prototype._clone = function () {
  return new NimoBuss.GetAreasReq();
};
GetAreasReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetAreasReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetAreasReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.language);
};
GetAreasReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.language = is.readString(1, false, this.language);
};
NimoBuss.GetAreasReq = GetAreasReq;
export default GetAreasReq;
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './WorldLangEntity';
var LanguageSettingView = function LanguageSettingView() {
  this.worldLangList = new Taf.Vector(new NimoBuss.WorldLangEntity());
};
LanguageSettingView.prototype._clone = function () {
  return new NimoBuss.LanguageSettingView();
};
LanguageSettingView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LanguageSettingView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LanguageSettingView.prototype.writeTo = function (os) {
  os.writeVector(0, this.worldLangList);
};
LanguageSettingView.prototype.readFrom = function (is) {
  this.worldLangList = is.readVector(0, false, this.worldLangList);
};
NimoBuss.LanguageSettingView = LanguageSettingView;
export default LanguageSettingView;
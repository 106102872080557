import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var McUser = function McUser() {
  this.lUid = 0;
  this.sName = "";
  this.sImageUrl = "";
  this.iIndex = 0;
  this.sStreamKey = 0;
  this.iSex = 0;
  this.iType = 0;
  this.sCountry = "";
  this.sAvatarBoxUrl = "";
  this.iRoyalLevel = 0;
  this.sDynamicAvatarBoxUrl = "";
};
McUser.prototype._clone = function () {
  return new Nimo.McUser();
};
McUser.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
McUser.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
McUser.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sName);
  os.writeString(2, this.sImageUrl);
  os.writeInt32(3, this.iIndex);
  os.writeUInt32(4, this.sStreamKey);
  os.writeInt32(5, this.iSex);
  os.writeInt32(6, this.iType);
  os.writeString(7, this.sCountry);
  os.writeString(8, this.sAvatarBoxUrl);
  os.writeInt32(9, this.iRoyalLevel);
  os.writeString(10, this.sDynamicAvatarBoxUrl);
};
McUser.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sName = is.readString(1, false, this.sName);
  this.sImageUrl = is.readString(2, false, this.sImageUrl);
  this.iIndex = is.readInt32(3, false, this.iIndex);
  this.sStreamKey = is.readUInt32(4, false, this.sStreamKey);
  this.iSex = is.readInt32(5, false, this.iSex);
  this.iType = is.readInt32(6, false, this.iType);
  this.sCountry = is.readString(7, false, this.sCountry);
  this.sAvatarBoxUrl = is.readString(8, false, this.sAvatarBoxUrl);
  this.iRoyalLevel = is.readInt32(9, false, this.iRoyalLevel);
  this.sDynamicAvatarBoxUrl = is.readString(10, false, this.sDynamicAvatarBoxUrl);
};
Nimo.McUser = McUser;
export default McUser;
import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var MatchRecommendReq = function MatchRecommendReq() {
  this.currentRoomId = 0;
  this.udbUserId = 0;
  this.gameType = 0;
  this.countryCode = "";
  this.language = "";
  this.lcid = 0;
  this.requestSource = "";
  this.baseParam = new NimoBuss.BaseParam();
};
MatchRecommendReq.prototype._clone = function () {
  return new NimoBuss.MatchRecommendReq();
};
MatchRecommendReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchRecommendReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchRecommendReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.currentRoomId);
  os.writeInt64(1, this.udbUserId);
  os.writeInt64(2, this.gameType);
  os.writeString(3, this.countryCode);
  os.writeString(4, this.language);
  os.writeInt32(5, this.lcid);
  os.writeString(6, this.requestSource);
  os.writeStruct(7, this.baseParam);
};
MatchRecommendReq.prototype.readFrom = function (is) {
  this.currentRoomId = is.readInt64(0, false, this.currentRoomId);
  this.udbUserId = is.readInt64(1, false, this.udbUserId);
  this.gameType = is.readInt64(2, false, this.gameType);
  this.countryCode = is.readString(3, false, this.countryCode);
  this.language = is.readString(4, false, this.language);
  this.lcid = is.readInt32(5, false, this.lcid);
  this.requestSource = is.readString(6, false, this.requestSource);
  this.baseParam = is.readStruct(7, false, this.baseParam);
};
NimoBuss.MatchRecommendReq = MatchRecommendReq;
export default MatchRecommendReq;
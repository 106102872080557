import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EOfficialID = {
  EGameNotify: -10,
  EFOLLOW: -9,
  EIDAt: -8,
  EIDExpress: -7,
  EIDClump: -6,
  EIDVipService: -5,
  EIDLikeNotify: -4,
  EIDCommentNotify: -3,
  EIDStreamerService: -2,
  EIDAssist: -1,
  EIDSysNotify: 0
};
Nimo.EOfficialID = EOfficialID;
export default EOfficialID;
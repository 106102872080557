import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var RegionView = function RegionView() {
  this.code = "";
  this.name = "";
};
RegionView.prototype._clone = function () {
  return new NimoBuss.RegionView();
};
RegionView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RegionView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RegionView.prototype.writeTo = function (os) {
  os.writeString(0, this.code);
  os.writeString(1, this.name);
};
RegionView.prototype.readFrom = function (is) {
  this.code = is.readString(0, false, this.code);
  this.name = is.readString(1, false, this.name);
};
NimoBuss.RegionView = RegionView;
export default RegionView;
import { transformUpperKebabCaseToCamelCase } from '@client/common/utils/string';
/**
 * @template T
 * @typedef {import('type-fest').CamelCase<T>} CamelCase<T>
 */

var EMessageId = {
  JUMP_OUTER_BROWSER: 990,
  // 打开外部浏览器
  POPUP_SHARE: 991,
  // 调起分享面板
  FOLLOW_EVENT: 992,
  // 关注事件通知
  SHOW_USER_INFO_DIALOG: 993,
  // 显示用户信息面板
  GET_LIVE_ROOM_INFO: 994,
  // 获取直播间信息
  POPUP_LOGIN: 995,
  // 调起登录弹窗
  JUMP_TO_LIVE_ROOM: 996,
  // 跳转到直播间
  SHOW_TOAST: 997,
  // 调用容器的 toast 组件，显示提示信息
  REGISTER_SOCKET_MSG: 998,
  // 注册监听广播消息
  UNREGISTER_SOCKET_MSG: 999,
  // 反注册监听广播消息
  OPEN_ACTIVITY_MODAL: 1000,
  // 打开活动弹窗
  RESIZE_WEB_VIEW: 1001,
  // 改变容器尺寸
  OPEN_ROOM_GIFT_PANEL: 1002,
  // 调起礼物面板，isPackage参数暂时不支持
  REGISTER_BUSINESS_MSG: 1003,
  // 注册业务消息通知
  SET_BUSINESS_MSG_CALLBACK: 1004,
  // 设置业务消息通知回调
  UNREGISTER_BUSINESS_MSG: 1005,
  // 取消注册业务消息通知
  POPUP_JOIN_FANS_MODAL: 1008,
  // 弹出加入粉丝团弹窗
  POPUP_PAID_SUBSCRIPTION_MODAL: 1009,
  // 弹出付费订阅面板
  CLOSE_ACTIVITY_MODAL: 1010,
  // 关闭活动弹窗
  SUBSCRIBE_TOPIC: 1011,
  // 订阅指定频道（进组）
  UNSUBSCRIBE_TOPIC: 1012,
  // 退订指定频道（退组）
  UPDATE_ACCOUNT_BALANCE: 1013,
  // 更新账户余额，仅支持Iframe.js，其他端暂无
  OPEN_FINAL_BOX: 1014,
  // 打开2021年度总决赛红包面板 （仅支持在web直播间）
  HOIST_EMBED_INFO: 1015,
  // 透传内嵌播放器信息，仅支持Iframe.js
  OPEN_FANS_BOX: 1016,
  // 打开平台粉丝日红包面板 （仅支持在web直播间）
  CLOSE_SELF: 1017,
  // 关闭自己
  OPEN_RAMADAN_BOX: 1018,
  OPEN_WEEK_STAR_ACTIVITY_MODAL: 1019,
  // 打开周星活动页弹窗
  OPEN_WORLD_CUP_MODAL: 1020,
  // 打开世界杯活动弹窗（仅支持在web直播间）
  OPEN_INNER_BROWSER: 1021,
  // 打开全屏webview内部浏览器，在iframe、web里表现与OPEN_ACTIVITY_MODAL表现一致
  OPEN_RAMADAN23_BOX: 1022,
  // 斋月2023
  OPEN_TOP_CP: 1023,
  // cp 活动
  REVERT_ACCOUNT_SUCCESS: 1041,
  // 恢复注销账号成功的回调
  FOCUS_CHAT_INPUT: 1049,
  // 打开公屏输入框
  POPUP_FANS_CLUB_PANEL: 899,
  // 弹出粉丝团面板
  // 播放器相关
  DISPATCH_PLAYER_EVENT: 10001 // 同步播放器事件（ready，play，pause，ended，state）
};

/**
 * @typedef {typeof EMessageId} T_MessageId
 */
/**
 * @type {[T_MessageId[keyof T_MessageId], CamelCase<keyof T_MessageId>][]}
 */
var EMessageName = function getMessageNameArr() {
  return Object.keys(EMessageId).map(function (k) {
    return [EMessageId[k], transformUpperKebabCaseToCamelCase(k)];
  });
}();
/**
 * @typedef {typeof EMessageName[0][1]} MessageMethodName
 */
/**
 * 业务消息通知类型 id 集合，>= 2000
 */
var EBusinessMessageId = {
  SYNC_ROOM_INFO: 2000,
  // 同步直播间状态
  SYNC_MODAL_VISIBILITY: 2001,
  // 同步弹窗显示/隐藏状态
  // 播放器相关
  INVOKE_PLAYER_PLAY: 3001,
  // 播放器播放
  INVOKE_PLAYER_PAUSE: 3002,
  // 播放器暂停
  INVOKE_PLAYER_GET_PLAYER_STATE: 3003,
  // 获取播放器当前状态
  INVOKE_PLAYER_MUTED: 3004 // 播放器静音状态
};
var REQUIRE_SAFE_ID = [EMessageId.HOIST_EMBED_INFO];
export { EMessageId, EBusinessMessageId, EMessageName, REQUIRE_SAFE_ID };
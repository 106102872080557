import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MeetingThemeInfo = function MeetingThemeInfo() {
  this.lRoomID = 0;
  this.iThemeID = 0;
  this.sThemeURL = "";
  this.sThemePreview = "";
};
MeetingThemeInfo.prototype._clone = function () {
  return new Nimo.MeetingThemeInfo();
};
MeetingThemeInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingThemeInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingThemeInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomID);
  os.writeInt32(1, this.iThemeID);
  os.writeString(2, this.sThemeURL);
  os.writeString(3, this.sThemePreview);
};
MeetingThemeInfo.prototype.readFrom = function (is) {
  this.lRoomID = is.readInt64(0, false, this.lRoomID);
  this.iThemeID = is.readInt32(1, false, this.iThemeID);
  this.sThemeURL = is.readString(2, false, this.sThemeURL);
  this.sThemePreview = is.readString(3, false, this.sThemePreview);
};
Nimo.MeetingThemeInfo = MeetingThemeInfo;
export default MeetingThemeInfo;
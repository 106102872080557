import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ShareRewardNotice = function ShareRewardNotice() {
  this.udbUserId = 0;
  this.nickName = "";
  this.noticeContent = "";
  this.roomId = 0;
};
ShareRewardNotice.prototype._clone = function () {
  return new Nimo.ShareRewardNotice();
};
ShareRewardNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRewardNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRewardNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeString(1, this.nickName);
  os.writeString(2, this.noticeContent);
  os.writeInt64(3, this.roomId);
};
ShareRewardNotice.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.nickName = is.readString(1, false, this.nickName);
  this.noticeContent = is.readString(2, false, this.noticeContent);
  this.roomId = is.readInt64(3, false, this.roomId);
};
Nimo.ShareRewardNotice = ShareRewardNotice;
export default ShareRewardNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RichText';
var ConfessionMessageNotice = function ConfessionMessageNotice() {
  this.tMessage = new Nimo.RichText();
  this.sGiftIcon = "";
  this.iGiftNum = 0;
  this.iConfessType = 0;
};
ConfessionMessageNotice.prototype._clone = function () {
  return new Nimo.ConfessionMessageNotice();
};
ConfessionMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ConfessionMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ConfessionMessageNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tMessage);
  os.writeString(1, this.sGiftIcon);
  os.writeInt32(2, this.iGiftNum);
  os.writeInt32(3, this.iConfessType);
};
ConfessionMessageNotice.prototype.readFrom = function (is) {
  this.tMessage = is.readStruct(0, false, this.tMessage);
  this.sGiftIcon = is.readString(1, false, this.sGiftIcon);
  this.iGiftNum = is.readInt32(2, false, this.iGiftNum);
  this.iConfessType = is.readInt32(3, false, this.iConfessType);
};
Nimo.ConfessionMessageNotice = ConfessionMessageNotice;
export default ConfessionMessageNotice;
import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var VoiceRoomBanNotice = function VoiceRoomBanNotice() {
  this.lBannedUid = 0;
  this.lRoomId = 0;
  this.lTimeS = 0;
  this.sBannedNick = "";
  this.lOperatorUid = 0;
  this.sOperatorNick = "";
  this.iIsAnchor = 0;
  this.iIsOnVoice = 0;
};
VoiceRoomBanNotice.prototype._clone = function () {
  return new Nimo.VoiceRoomBanNotice();
};
VoiceRoomBanNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VoiceRoomBanNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VoiceRoomBanNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lBannedUid);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lTimeS);
  os.writeString(3, this.sBannedNick);
  os.writeInt64(4, this.lOperatorUid);
  os.writeString(5, this.sOperatorNick);
  os.writeInt32(6, this.iIsAnchor);
  os.writeInt32(7, this.iIsOnVoice);
};
VoiceRoomBanNotice.prototype.readFrom = function (is) {
  this.lBannedUid = is.readInt64(0, false, this.lBannedUid);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lTimeS = is.readInt64(2, false, this.lTimeS);
  this.sBannedNick = is.readString(3, false, this.sBannedNick);
  this.lOperatorUid = is.readInt64(4, false, this.lOperatorUid);
  this.sOperatorNick = is.readString(5, false, this.sOperatorNick);
  this.iIsAnchor = is.readInt32(6, false, this.iIsAnchor);
  this.iIsOnVoice = is.readInt32(7, false, this.iIsOnVoice);
};
Nimo.VoiceRoomBanNotice = VoiceRoomBanNotice;
export default VoiceRoomBanNotice;
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import meetingSocketModel from '@client/common/models/socket/meetingSocketModel';
import meetingWupModel from '@client/common/models/wup/meetingWupModel';
// import EControlOperations from '@client/jce/Nimo/EControlOperations';

import { useEffect, useState } from 'react';
var defaultState = {
  version: -1,
  value: {},
  mHatInfo: {},
  isOn: false
};
export var useCharm = function useCharm(anchorId, meetingInfo) {
  var _useState = useState(defaultState),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var _ref = meetingInfo || {},
    iMeetingStatus = _ref.iMeetingStatus;
  useEffect(function () {
    if (!anchorId) return undefined;
    var handleCharmValue = function handleCharmValue(res) {
      var lVersion = res.lVersion,
        mGuestCharmValue = res.mGuestCharmValue,
        mHatInfo = res.mHatInfo,
        lAnchorUid = res.lAnchorUid,
        iOperations = res.iOperations;
      if (String(lAnchorUid) === String(anchorId)) {
        setState({
          version: lVersion,
          value: mGuestCharmValue,
          mHatInfo: mHatInfo,
          isOn: iOperations !== 0
        });
      }
    };
    meetingSocketModel.on('CharmValue', handleCharmValue);
    return function () {
      meetingSocketModel.off('CharmValue', handleCharmValue);
    };
  }, [anchorId]);
  useEffect(function () {
    if (iMeetingStatus === 1 && anchorId) {
      meetingWupModel.getCharmValue(anchorId).then(function (res) {
        var lVersion = res.lVersion,
          mGuestCharmValue = res.mGuestCharmValue,
          iOperations = res.iOperations,
          mHatInfo = res.mHatInfo;
        setState({
          version: lVersion,
          value: mGuestCharmValue,
          mHatInfo: mHatInfo,
          isOn: iOperations !== 0
        });
      });
    } else if (iMeetingStatus === 0) {
      setState(defaultState);
    }
  }, [iMeetingStatus, anchorId]);
  return state;
};